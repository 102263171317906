/**
 */
var modules = modules || {}
var EDIT_TYPE_ALL = 'ALL',
  EDIT_TYPE_FOLLOWING = 'FOLLOWING',
  EDIT_TYPE_OVERRIDE = 'OVERRIDE'

modules.shiftslideout = (function () {
  var helpers = {
    weekDaysOptions: {
      Sunday: 'S',
      Monday: 'M',
      Tuesday: 'T',
      Wednesday: 'W',
      Thursday: 'TH',
      Friday: 'F',
      Saturday: 'SA',
    },
    weekDayAbbreviationsByIndex: ['M', 'T', 'W', 'TH', 'F', 'SA', 'S'],

    shiftCategoryOptions: _.object([
      ['', ''],
      ['BREAKFAST', 'Breakfast'],
      ['BRUNCH', 'Brunch'],
      ['LUNCH', 'Lunch'],
      ['DAY', 'Day'],
      ['DINNER', 'Dinner'],
      ['LEGACY', 'Night'],
    ]),

    timeIntervalOptions: _.object([
      ['', ''],
      ['15', '15 minutes (recommended)'],
      ['30', '30 minutes'],
      ['60', '60 minutes'],
    ]),

    advanceBookingOptions: _.object([
      ['INDEFINITE', 'indefinitely (recommended)'],
      ['HOURS', 'hours in advance'],
      ['DAYS', 'days in advance'],
      ['WEEKS', 'weeks in advance'],
      ['MONTHS', 'months in advance'],
    ]),

    subTextCssStyle: {
      clear: 'both',
      color: '#aaa',
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: 11,
    },

    wrappingSelectStyle: {
      width: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      backgroundPositionX: '96%',
      paddingRight: '20px',
    },

    getBufferTimes: intervalMin => {
      return [...Array(5).keys()].map(x => x * intervalMin).filter(x => x > 0 && x <= 60)
    },

    getBufferTimesDisplay: intervalMin => {
      return helpers.getBufferTimes(intervalMin).reduce((acc, curr) => {
        acc[curr] = `${curr} MIN`
        return acc
      }, {})
    },

    scheduleCategory: React.createClass({
      render: function () {
        return (
          <div className="schedule-name">
            <modules.formElements.create
              type="select"
              name="category"
              disabled={this.props.is_edit}
              validator="not_empty"
              labelText="Shift category"
              prelabel="Shift category"
              options={helpers.shiftCategoryOptions}
              media_url={this.props.media_url}
              required="True"
              value={this.props.value}
              validator_message="Please pick the shift category"
            />
          </div>
        )
      },
    }),

    shiftReportingPeriods: React.createClass({
      render: function () {
        return (
          <div className="schedule-reporting-period">
            <modules.formElements.create
              type="select"
              name="shift_reporting_period_id"
              labelText="Shift Reporting Periods Group"
              prelabel="Shift Reporting Periods Group"
              options={_.object([['', '']].concat(this.props.shift_reporting_periods.map(period => [period.id, period.name])))}
              media_url={this.props.media_url}
              required={false}
              value={this.props.value}
            />
          </div>
        )
      },
    }),

    scheduleName: React.createClass({
      render: function () {
        return (
          <div className="inline schedule-name" style={{ marginBottom: 10 }}>
            <modules.formElements.create
              type="input"
              name="name"
              disabled={this.props.is_edit}
              style={{ marginTop: 10 }}
              labelText="Shift name"
              prelabel="Shift name"
              media_url={this.props.media_url}
              value={this.props.value}
              placeholder="e.g. Dinner Weekend"
            />
          </div>
        )
      },
    }),

    shiftArea: React.createClass({
      getInitialState: function () {
        const allSeatingAreaIds = this.props.areas.map(area => area.id)
        const selectedSeatingAreas = this.props.selected_areas ?? []
        const isAllSelected =
          selectedSeatingAreas.length === 0 ||
          new Set(allSeatingAreaIds).intersection(new Set(selectedSeatingAreas)).size === allSeatingAreaIds.length
        return {
          isAllSelected,
          selectedSeatingAreas: isAllSelected ? allSeatingAreaIds : selectedSeatingAreas,
        }
      },

      onSeatingAreaClick: function (areaId) {
        const isCurrentlySelected = this.state.selectedSeatingAreas.includes(areaId)
        const newSelectedSeatingAreas = isCurrentlySelected
          ? this.state.selectedSeatingAreas.filter(id => id !== areaId)
          : [...this.state.selectedSeatingAreas, areaId]

        let newIsAllSelected = this.state.isAllSelected
        if (this.state.isAllSelected && newSelectedSeatingAreas.length !== this.props.areas.length) {
          newIsAllSelected = false
        }
        if (!this.state.isAllSelected && newSelectedSeatingAreas.length === this.props.areas.length) {
          newIsAllSelected = true
        }

        this.setState({
          isAllSelected: newIsAllSelected,
          selectedSeatingAreas: newSelectedSeatingAreas,
        })
      },

      onAllClick: function () {
        this.setState({
          isAllSelected: !this.state.isAllSelected,
          selectedSeatingAreas: this.state.isAllSelected ? [] : this.props.areas.map(area => area.id),
        })
      },

      render: function () {
        const preInputStyle = {
          border: '1px solid #CCC',
          padding: 3,
          position: 'relative',
          left: 0,
          borderRadius: 3,
          WebkitBorderRadius: 3,
          lineHeight: 20,
        }
        const postInputStyle = {
          float: 'left',
          margin: 0,
          border: 0,
          color: '#AAA !important',
          top: -5,
          left: 10,
          textAlign: 'left',
          height: 20,
          backgroundColor: 'transparent !important',
        }
        const allDivCss = {
          float: 'left',
          clear: 'initial',
          width: '100%',
          margin: 3,
        }
        const seatingAreaDivCss = {
          ...allDivCss,
          marginLeft: 30,
        }

        const sortedSeatingAreas = [...this.props.areas].sort((a, b) => a.sort_order - b.sort_order)

        return (
          <div className="inline" style={{ marginBottom: 10 }}>
            <p className="group-label">Seating areas available</p>
            <p className="label" style={helpers.subTextCssStyle}>
              Tables active on the layout in selected seating areas will receive auto-assigned reservations. Tables in unselected seating
              areas will only be bookable via manual assignments or walk-ins.
            </p>
            <div className="radioset">
              <modules.formElements.create
                type="checkbox"
                label="All"
                testId="sr-checkbox-all"
                labelStyle={{ margin: 0 }}
                checked={this.state.isAllSelected}
                value="all"
                id="seating-areas-all"
                preInputStyle={preInputStyle}
                postLabelStyle={postInputStyle}
                inputCss={{ border: 0 }}
                style={allDivCss}
                onClickHandler={this.onAllClick}
              />
              {sortedSeatingAreas.map((seatingArea, index) => {
                const id = `id_shift_area_${index}`
                return (
                  <modules.formElements.create
                    type="checkbox"
                    label={seatingArea.name}
                    key={index}
                    testId={`sr-checkbox-${id}`}
                    labelStyle={{ margin: 0 }}
                    checked={this.state.selectedSeatingAreas.includes(seatingArea.id)}
                    value={seatingArea.id}
                    name="area_ids"
                    preInputStyle={preInputStyle}
                    postLabelStyle={postInputStyle}
                    inputCss={{ border: 0 }}
                    id={id}
                    style={seatingAreaDivCss}
                    onClickHandler={() => this.onSeatingAreaClick(seatingArea.id)}
                  />
                )
              })}
            </div>
          </div>
        )
      },
    }),

    shiftDaySection: React.createClass({
      onClickHandler: function (e) {
        var currentTarget = $(e.currentTarget),
          isChecked = $(currentTarget).is(':checked'),
          selectedDay = $(currentTarget).data('additional'),
          currentWeekDate = new Date(this.props.startDay),
          dayName = currentWeekDate.toLocaleString('default', { weekday: 'long' }),
          dayIndex = _.indexOf(_.keys(helpers.weekDaysOptions), dayName)
        currentWeekDate.addDays(-dayIndex)
        var dateLookup = _.object(
            _.times(7, function (i) {
              var tuple = [currentWeekDate.toLocaleString('default', { weekday: 'long' }), formatDate(currentWeekDate)]
              currentWeekDate.addDays(1)
              return tuple
            })
          ),
          startTime = $(currentTarget).closest('form').find('[name=start_time]').val(),
          endTime = $(currentTarget).closest('form').find('[name=end_time]').val(),
          date = dateLookup[selectedDay],
          startTimeParts = modules.weekViewManager.getHourFraction(startTime),
          endTimeParts = modules.weekViewManager.getHourFraction(endTime),
          startDateTime = new Date(dateLookup[selectedDay] + ' ' + startTime),
          endDateTime = new Date(dateLookup[selectedDay] + ' ' + endTime)

        startDateTime.addDays((startTimeParts.hour >= 0) & (startTimeParts.hour < 6) ? 1 : 0)
        endDateTime.addDays((endTimeParts.hour >= 0) & (endTimeParts.hour < 6) ? 1 : 0)

        var hours = startDateTime.getHours(),
          fraction = startDateTime.getMinutes() / 15,
          total_duration = (endDateTime - startDateTime) / 3600000

        if (helpers.mode === 'add') {
          this.props.addOverlayCallback(date, hours, fraction, total_duration, isChecked)
        }
      },

      render: function () {
        var postInputCss = {
            clear: 'both',
            margin: 0,
            border: 0,
            color: '#000 !important',
            width: 14,
            backgroundColor: 'transparent !important',
          },
          divCss = {
            width: 55,
          }

        var options = _.map(
          helpers.weekDaysOptions,
          function (value, key) {
            return (
              <modules.formElements.create
                type="checkbox"
                label={value}
                key={key}
                data-name={key}
                name="days"
                value={value}
                dataAdditional={key}
                checked={_.indexOf(this.props.selectedDays, value) > -1 ? true : false}
                validator-type="not_empty"
                postLabelStyle={postInputCss}
                inputCss={_.extend({ marginLeft: 4 }, { border: 0 })}
                id={'id_rule_' + key}
                testId={`sr-checkbox-${key}`}
                style={divCss}
                preInputStyle={{ position: 'relative', left: 0, border: '1px solid #ccc', padding: 2 }}
                validator="not_empty"
                onClickHandler={this.onClickHandler}
                validator_message="Please select a day"
              />
            )
          },
          this
        )

        return <div className="radioset">{options}</div>
      },
    }),

    floorPlanLayout: React.createClass({
      render: function () {
        var layout_id
        if (this.props.layout_id) {
          layout_id = this.props.layout_id
        } else {
          var selected_layout = _.findWhere(this.props.layouts, { layout_id: 'default' })
          if (selected_layout) {
            layout_id = selected_layout.id
          }
        }
        return (
          <div className="inline" style={{ marginBottom: 10 }}>
            <p className="group-label">Floorplan layout</p>
            <modules.formElements.create
              type="select"
              value={layout_id}
              name="floorplan"
              options={_.object(_.collect(this.props.layouts, 'id'), _.collect(this.props.layouts, 'layout_id'))}
              media_url={this.props.media_url}
            />
          </div>
        )
      },
    }),

    advanceBookingLimits: React.createClass({
      getInitialState: function () {
        var defaultHour
        if (this.props.internal_cutoff_type === 'HOURS') {
          defaultHour = '0'
        } else {
          defaultHour = modules.weekViewManager.getMinutesDisplay(60 * 10)
        }
        const accessHour = this.props.internal_cutoff_hour !== undefined ? this.props.internal_cutoff_hour : defaultHour
        return {
          media_url: this.props.media_url,
          internal_cutoff_num: this.props.internal_cutoff_num,
          internal_cutoff_type: this.props.internal_cutoff_type || 'INDEFINITE',
          internal_cutoff_hour: accessHour,
        }
      },

      updateType: function (e) {
        this.setState({ internal_cutoff_type: e.target.value })
        if (e.target.value === 'INDEFINITE') {
          this.setState({ internal_cutoff_num: '' })
        }
      },

      updateNum: function (e) {
        this.setState({ internal_cutoff_num: e.target.value })
      },

      updateTime: function (e) {
        this.setState({ internal_cutoff_hour: e.target.value })
      },

      render: function () {
        const timeOptions = _.extend(
          {},
          { 0: 'reservation time' },
          this.state.internal_cutoff_type === 'HOURS' ? [] : helpers.getShiftTimes()
        )
        return (
          <div className="inline" style={{ marginBottom: 10 }}>
            <p className="group-label">How far in advance can reservations be booked internally?</p>
            <modules.formElements.create
              type="input"
              name="internal_cutoff_num"
              disabled={this.state.internal_cutoff_type === 'INDEFINITE'}
              media_url={this.props.media_url}
              value={this.state.internal_cutoff_num}
              inputCss={{ height: '23px', width: '40px' }}
              onChangeHandler={this.updateNum}
              validator="strict_int_positive"
              validator_message="Accepts > 0 only"
            />
            <modules.formElements.create
              type="select"
              value={this.state.internal_cutoff_type}
              name="internal_cutoff_type"
              preInputStyle={{ width: '10.5em' }}
              options={helpers.advanceBookingOptions}
              media_url={this.props.media_url}
              onChangeHandler={this.updateType}
              inputCss={helpers.wrappingSelectStyle}
            />
            {this.state.internal_cutoff_type !== 'INDEFINITE' ? (
              <modules.formElements.create
                type="select"
                value={this.state.internal_cutoff_hour}
                name="internal_cutoff_hour"
                preInputStyle={{ width: '130px' }}
                options={timeOptions}
                media_url={this.props.media_url}
                onChangeHandler={this.updateTime}
              />
            ) : null}
          </div>
        )
      },
    }),

    lastSeatingTime: React.createClass({
      render: function () {
        return (
          <div className="inline">
            <div style={{ fontWeight: 'bold', fontSize: 12, color: '#aaa', margin: '0px 0px 3px' }} name="last-seating-time">
              Last seating
            </div>
            <div className="last-seating-time" style={{ color: '#AAA', marginBottom: 10, marginTop: 10 }}>
              {this.props.endTime}
            </div>
          </div>
        )
      },
    }),

    twoDropDowns: React.createClass({
      render: function () {
        var selectCss = {
          border: 0,
        }
        return (
          <div className="inline" style={{ width: '100%', marginBottom: 10 }}>
            {this.props.label ? <p className="group-label">{this.props.label}</p> : undefined}
            <modules.formElements.create
              type="select"
              inputCss={selectCss}
              value={this.props.select1.selected}
              name={this.props.select1.name}
              onChangeHandler={this.props.select1.onChangeHandler}
              classes={this.props.select1.classes}
              options={this.props.select1.options}
              media_url={this.props.media_url}
              labelText={this.props.select1.id}
            />
            <modules.formElements.create
              type="select"
              inputCss={selectCss}
              name={this.props.select2.name}
              onChangeHandler={this.props.select2.onChangeHandler}
              value={this.props.select2.selected}
              classes={this.props.select2.classes}
              options={this.props.select2.options}
              media_url={this.props.media_url}
              labelText={this.props.select2.id}
            />
          </div>
        )
      },
    }),

    getAllDayTimes: function () {
      var interval_minutes = 15,
        interval_multiplier = interval_minutes / 15
      return _.filter(
        _.flatten(
          _.times(24, function (hour) {
            hour = (hour + Pmp.Manager.Global.start_of_day_hour) % 24
            return _.times(4, function (fraction) {
              return modules.weekViewManager.getMinutesDisplay(hour * 60 + fraction * 15)
            })
          })
        ),
        function (v, k) {
          return k % interval_multiplier == 0
        }
      )
    },

    getShiftTimes: function (startTime, endTime, inclusive, intervalMin) {
      var interval_minutes = intervalMin ? parseInt(intervalMin) : 15
      var allTimes = helpers.getAllDayTimes()
      var startIndex = startTime ? _.indexOf(allTimes, startTime) : 0,
        endIndex = endTime ? _.indexOf(allTimes, endTime) : _.size(allTimes),
        allTimes = allTimes.slice(startIndex, endIndex + !!inclusive),
        applicableTimes = helpers.filterTimes(allTimes, interval_minutes)

      return _.object(applicableTimes, applicableTimes)
    },

    filterTimes: function (allTimes, interval) {
      var allTimes = _.filter(allTimes, function (time) {
        var min = time.split(':')[1].split(' ')[0]
        if (interval === 15) {
          return true
        } else if (interval === 30) {
          return min === '30' || min === '00'
        } else if (interval === 60) {
          return min === '00'
        }
        return false
      })
      return allTimes
    },

    getAvgTurnaroundTime: function () {
      var merged = {}
      _.times(
        16 * 4,
        function (quarter_hours) {
          quarter_hours += 1
          merged[quarter_hours * 15] = [Math.floor(quarter_hours / 4), 'HR', ('0' + (quarter_hours % 4) * 15).slice(-2), 'MIN'].join(' ')
        },
        this
      )
      return merged
    },

    shiftTimeIntervals: React.createClass({
      getInitialState: function () {
        let startTime = this.props.startTime,
          endTime = this.props.endTime,
          intervalMin = this.props.intervalMin

        const adjusted = this.getAdjustedIntervalAndOptions(startTime, endTime, intervalMin)

        return {
          startTime: startTime,
          endTime: endTime,
          intervalMin: adjusted.intervalMin,
          options: adjusted.options,
        }
      },

      getAdjustedIntervalAndOptions: function (startTime, endTime, intervalMin) {
        let options = helpers.timeIntervalOptions
        let curInterval = Number(intervalMin)

        // If we have a start or end time that is at XX:15 or XX:45, we only allow 15-minute intervals
        // If we have a start or end time that is at XX:30, we only allow 15-minute and 30-minute intervals
        if (startTime && endTime) {
          const s = String(startTime.split(':')?.[1])
          const e = String(endTime.split(':')?.[1])
          if (['15', '45'].some(sub => s.includes(sub) || e.includes(sub))) {
            if (curInterval !== 15) {
              curInterval = ''
            }
            options = { 15: options[15], '': options[''] }
          } else if (s.includes('30') || e.includes('30')) {
            if (curInterval !== 15 && curInterval !== 30) {
              curInterval = ''
            }
            options = { 15: options[15], 30: options[30], '': options[''] }
          }
        }
        return { intervalMin: curInterval, options }
      },

      onChangeHandler: function (e) {
        let startTime = $(e.currentTarget).filter('[name=start_time]')
        let endTime = $(e.currentTarget).filter('[name=end_time]')
        let intervalMin = $(e.currentTarget).filter('[name=interval_minutes]')
        let allOptions = _.values(helpers.getShiftTimes())
        let maxIndex = _.size(allOptions) - 1

        intervalMin = intervalMin.length ? intervalMin.val() : this.state.intervalMin

        if (startTime.length) {
          startTime = startTime.val()
          let prevEndTime = this.state.endTime || allOptions[0]
          let startTimeIndex = _.indexOf(allOptions, startTime)
          let endTimeIndex = _.indexOf(allOptions, prevEndTime)
          let isEndTimeInvalid = endTimeIndex <= startTimeIndex
          let newEndTime = isEndTimeInvalid ? allOptions[maxIndex - startTimeIndex > 12 ? startTimeIndex + 12 : maxIndex] : prevEndTime

          const adjusted = this.getAdjustedIntervalAndOptions(startTime, prevEndTime, intervalMin)
          intervalMin = adjusted.intervalMin
          let options = adjusted.options

          this.setState({ startTime: startTime, endTime: newEndTime, intervalMin: intervalMin, options: options })
          modules.shiftWeekViewManager.resetResizableOverlays(startTime, newEndTime)
          $('.last-seating-time').html(newEndTime)
          helpers.customPacingComponent.setState({ startTime: startTime, endTime: newEndTime, intervalMin: intervalMin })
          helpers.defaultTurnTimeComponent.setState({ startTime: startTime, endTime: newEndTime })
        }
        if (endTime.length) {
          endTime = endTime.val()
          let prevStartTime = this.state.startTime || allOptions[0]
          let startTimeIndex = _.indexOf(allOptions, prevStartTime)
          let endTimeIndex = _.indexOf(allOptions, endTime)
          let isStartTimeInvalid = endTimeIndex <= startTimeIndex
          let newStartTime = isStartTimeInvalid ? allOptions[endTimeIndex > 12 ? endTimeIndex - 12 : 0] : prevStartTime

          const adjusted = this.getAdjustedIntervalAndOptions(prevStartTime, endTime, intervalMin)
          intervalMin = adjusted.intervalMin
          let options = adjusted.options

          this.setState({ startTime: newStartTime, endTime: endTime, intervalMin: intervalMin, options: options })
          modules.shiftWeekViewManager.resetResizableOverlays(newStartTime, endTime)
          $('.last-seating-time').html(endTime)
          helpers.customPacingComponent.setState({ startTime: newStartTime, endTime: endTime, intervalMin: intervalMin })
          helpers.defaultTurnTimeComponent.setState({ startTime: newStartTime, endTime: endTime })
        }
        if (intervalMin && intervalMin.length) {
          this.setState({ intervalMin: intervalMin })
          helpers.customPacingComponent.setState({ intervalMin: intervalMin })

          const { addBufferTime, bufferMins: currentBufferMins } = helpers.defaultTurnTimeComponent.state
          intervalMin = parseInt(intervalMin)
          const bufferTimes = helpers.getBufferTimes(intervalMin)
          const venueSettings = window.globalInit.venueSettings

          helpers.defaultTurnTimeComponent.setState({
            intervalMin,
            bufferMins:
              !addBufferTime || !venueSettings.is_buffers_enabled || bufferTimes.includes(currentBufferMins)
                ? currentBufferMins
                : intervalMin,
          })
        }
      },

      render: function () {
        return (
          <div>
            <helpers.twoDropDowns
              select1={{
                name: 'start_time',
                onChangeHandler: this.onChangeHandler,
                selected: this.state.startTime,
                options: helpers.getShiftTimes(),
                id: 'First seating',
              }}
              joining_label="to"
              select2={{
                name: 'end_time',
                onChangeHandler: this.onChangeHandler,
                selected: this.state.endTime,
                options: helpers.getShiftTimes(this.state.startTime),
                id: 'Last seating',
              }}
              media_url={this.props.media_url}
            />
            <div className="inline" style={{ marginBottom: 10 }}>
              <modules.formElements.create
                type="select"
                labelText="Time Interval"
                value={this.state.intervalMin}
                name="interval_minutes"
                options={this.state.options}
                optionsFormatter={helpers.timeIntervalOptionsDisplay}
                onChangeHandler={this.onChangeHandler}
                media_url={this.props.media_url}
                inputCss={helpers.wrappingSelectStyle}
                required="True"
              />
            </div>
          </div>
        )
      },
    }),

    partiesSelection: React.createClass({
      getInitialState: function () {
        return {
          overbook_enforced_shift_party_size: this.props.overbook_enforced_shift_party_size,
          min: this.props.min,
          max: this.props.max,
        }
      },

      render: function () {
        return (
          <div className="inline" style={{ width: '100%', marginBottom: 10 }}>
            {this.props.label ? (
              <p className="group-label">
                {this.props.label}
                <span className="required">*</span>
              </p>
            ) : undefined}
            <div className="options">
              <p className="group-label">Party Size Limits</p>
            </div>
            <div style={{ paddingLeft: '8px' }}>
              <div className="inline">
                <modules.formElements.create
                  value={this.state.min}
                  style={{ fontFamily: 'Roboto' }}
                  labelText="Party size min"
                  min="1"
                  name="party_size_min"
                  validator="strict_int_positive"
                  validator_message="Accepts > 0 only"
                  type="number"
                />
                <modules.formElements.create
                  style={{ fontFamily: 'Roboto' }}
                  min={this.state.min}
                  value={this.state.max}
                  labelText="Party size max"
                  name="party_size_max"
                  validator="strict_int_positive"
                  validator_message="Accepts > 0 only"
                  type="number"
                />
              </div>

              <div className="inline">
                <p className="group-label">Enforce limitation for users without permission to overbook shift party size limitations</p>
                <div className="inline">
                  <modules.formElements.create
                    type="checkbox"
                    checked={!!this.state.overbook_enforced_shift_party_size}
                    name="overbook_enforced_shift_party_size"
                    id="is_overbook_enforced_shift_party_size"
                    label="&nbsp;"
                    labelStyle={{ marginTop: 0 }}
                    inputCss={{ border: 0 }}
                    preInputStyle={{ backgroundColor: '#FFF', padding: 3, margin: 0 }}
                    style={{ paddingLeft: 0 }}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      },
    }),

    buffersInput: React.createClass({
      getDefaultProps: function () {
        return {
          media_url: '',
          interval_minutes: 15,
          addBufferTime: false,
          bufferMins: 0,
          partySizeGT: 0,
          updateParent: _ => {},
        }
      },

      render: function () {
        const bufferTimes = helpers.getBufferTimesDisplay(this.props.interval_minutes)

        return (
          <div className="inline" style={{ width: '100%', marginBottom: 10 }}>
            <div className="inline">
              <div className="inline">
                <modules.formElements.create
                  type="checkbox"
                  checked={!!this.props.addBufferTime}
                  onClickHandler={e =>
                    this.props.updateParent({
                      addBufferTime: $(e.currentTarget).is(':checked'),
                    })
                  }
                  name="add_buffer_time"
                  id="add_buffer_time"
                  label="Add buffer time after reservation"
                  labelStyle={{ marginTop: 0 }}
                  inputCss={{ border: 0 }}
                  preInputStyle={{ backgroundColor: '#FFF', padding: 3, margin: 0 }}
                  style={{ paddingLeft: 0 }}
                />
              </div>
            </div>
            {!!this.props.addBufferTime && (
              <div style={{ paddingLeft: '32px' }}>
                <div className="inline" style={{ width: '100%', marginBottom: 10 }}>
                  <modules.formElements.create
                    style={{ maxWidth: '75px' }}
                    type="select"
                    inputCss={{
                      padding: '6px 8px',
                      marginLeft: 10,
                      border: 0,
                      backgroundPositionX: '60%',
                      backgroundPosition: '60%',
                    }}
                    value={this.props.bufferMins || null}
                    className="buffer_mins"
                    testId={`sr-select-buffer-mins`}
                    name={'buffer_mins'}
                    options={bufferTimes}
                    media_url={this.props.media_url}
                    onChangeHandler={e =>
                      this.props.updateParent({
                        bufferMins: parseInt(e.target.value ?? 0),
                      })
                    }
                  />
                </div>
                <p className="group-label" style={{ fontWeight: 'normal' }}>
                  for party sizes greater than
                </p>
                <div className="inline" style={{ width: '100%' }}>
                  <modules.formElements.create
                    style={{ fontFamily: 'Roboto', maxWidth: '75px' }}
                    min={0}
                    value={this.props.partySizeGT}
                    name="buffer_party_size_gt"
                    validator="int_positive"
                    validator_message="Party size is required"
                    type="number"
                    onChangeHandler={e =>
                      this.props.updateParent({
                        partySizeGT: parseInt(e.target.value ?? 0),
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )
      },
    }),

    defaultTurnTime: React.createClass({
      getInitialState: function () {
        var startTime = this.props.startTime,
          endTime = this.props.endTime,
          legacyTimeRangeDurationsKey = this.props.legacyTimeRangeDurationsKey,
          durationMinutesByPartySize = this.props.duration_minutes_by_party_size,
          durationDictRangePairs = helpers.convertDurationDictRangesToPairs(this.props.duration_dict_ranges)

        const theOneBufferEntry = Object.entries(this.props.bufferMinutesByPartySize)[0]
        const addBufferTime = theOneBufferEntry != null
        const [partySizeGT, bufferMins] = addBufferTime ? theOneBufferEntry : [null, this.props.interval_minutes]
        return {
          startTime: startTime,
          endTime: endTime,
          durationMinutesByPartySize: durationMinutesByPartySize,
          durationDictRangePairs: durationDictRangePairs,
          legacyTimeRangeDurationsKey: legacyTimeRangeDurationsKey,
          addBufferTime,
          bufferMins,
          partySizeGT,
          intervalMin: this.props.interval_minutes,
        }
      },

      componentDidMount: function () {
        helpers.defaultTurnTimeComponent = this
        this.updateLegacyTimeRangeDurationsKey(this.state.legacyTimeRangeDurationsKey)
      },

      componentDidUpdate: function (prevProps, prevState) {
        if (prevState.startTime !== this.state.startTime || prevState.endTime !== this.state.endTime) {
          var start_time_options = helpers.getShiftTimes(this.state.startTime, this.state.endTime, true)
          var lastDurationRange = {}
          var newDurationDictRangePairs = _.filter(this.state.durationDictRangePairs, function (pair) {
            if (!start_time_options[pair[0]]) {
              lastDurationRange = pair[1]
            }
            return start_time_options[pair[0]]
          })
          if (
            prevState.startTime !== this.state.startTime &&
            newDurationDictRangePairs.length !== this.state.durationDictRangePairs.length
          ) {
            newDurationDictRangePairs.unshift([this.state.startTime, lastDurationRange])
          }
          if (!newDurationDictRangePairs || newDurationDictRangePairs.length === 0) {
            var startTimeFormatted = Pmp.Utils.timeWithLocale(this.state.startTime)
            var defaultDurationDict = getDefaultDurationDictRanges(startTimeFormatted)
            newDurationDictRangePairs = _.pairs(defaultDurationDict)
          }
          this.setState({ durationDictRangePairs: newDurationDictRangePairs })
        }
      },

      onClickAddRangeHandler: function () {
        var durationDictRangePairs = _.clone(this.state.durationDictRangePairs)
        var last_pair = _.last(durationDictRangePairs)
        durationDictRangePairs.push([last_pair[0], _.clone(last_pair[1])])
        this.setState({
          durationDictRangePairs: durationDictRangePairs,
        })
      },

      onClickRemoveRangeHandler: function (range_index) {
        if (range_index === 0) {
          return // Can't remove default durations
        }
        var durationDictRangePairs = _.clone(this.state.durationDictRangePairs)
        durationDictRangePairs.splice(range_index, 1)
        this.setState({
          durationDictRangePairs: durationDictRangePairs,
        })
      },

      onClickLegacyTimeRangeDuration: function (e) {
        var selected_type = $(e.currentTarget).val()
        this.setState({ legacyTimeRangeDurationsKey: selected_type })
      },

      onChangeStartTime: function (range_index, start_time) {
        if (range_index === 0) {
          return // Can't change start time of default durations
        }
        var durationDictRangePairs = _.clone(this.state.durationDictRangePairs)
        durationDictRangePairs[range_index][0] = start_time
        this.setState({
          durationDictRangePairs: durationDictRangePairs,
        })
      },

      onChangeDuration: function (range_index, duration_index, duration) {
        var durationDictRangePairs = _.clone(this.state.durationDictRangePairs)
        durationDictRangePairs[range_index][1][duration_index] = duration
        this.setState({
          durationDictRangePairs: durationDictRangePairs,
        })
      },

      updateLegacyTimeRangeDurationsKey: function (legacyTimeRangeDurationsKey) {
        var $duration_start_times = $('.duration_start_times'),
          found_duration = false,
          $legacy_time_range_durations_key_default = $('#legacy_time_range_durations_key_0')
        if ($duration_start_times.length <= 1) {
          $legacy_time_range_durations_key_default.click()
          return
        }
        for (var i = 0; i < $duration_start_times.length; i++) {
          var $duration_start_time = $duration_start_times[i]
          if ($duration_start_time.value === legacyTimeRangeDurationsKey) {
            var duration_start_time_id = $duration_start_time.id,
              duration_id_split = duration_start_time_id.split('_'),
              duration_id = duration_id_split[duration_id_split.length - 1],
              $legacy_time_range_durations_key = $('#legacy_time_range_durations_key_' + duration_id)
            $legacy_time_range_durations_key.click()
            found_duration = true
            break
          }
        }
        if (!found_duration) {
          $legacy_time_range_durations_key_default.click()
        }
      },

      render: function () {
        var venue_settings = window.globalInit.venueSettings
        const { addBufferTime, bufferMins, partySizeGT, intervalMin } = this.state
        var that = this,
          media_url = this.props.media_url,
          startTime = this.state.startTime,
          endTime = this.state.endTime,
          legacyTimeRangeDurationsKey = this.state.legacyTimeRangeDurationsKey,
          start_time_options = helpers.getShiftTimes(startTime, endTime, true),
          range_index = -1,
          show_first_start_time = _.size(this.state.durationDictRangePairs) > 1,
          duration_ranges = _.map(this.state.durationDictRangePairs, function (pair, idx) {
            var is_default_range = idx === 0
            var range_start_time = pair[0],
              duration_dict = pair[1]
            range_index++
            return (
              <helpers.defaultTurnTimeRange
                media_url={media_url}
                duration_dict={duration_dict}
                start_time={range_start_time}
                range_index={range_index}
                legacyTimeRangeDurationsKey={legacyTimeRangeDurationsKey}
                is_default_range={is_default_range}
                start_time_options={start_time_options}
                show_start_time={is_default_range ? show_first_start_time : true}
                onChangeStartTime={that.onChangeStartTime}
                onChangeDuration={that.onChangeDuration}
                onClickLegacyTimeRangeDuration={that.onClickLegacyTimeRangeDuration}
                onClickRemoveRangeHandler={is_default_range ? null : that.onClickRemoveRangeHandler}
                showBuffer={venue_settings.is_buffers_enabled && addBufferTime}
                bufferMins={bufferMins}
                partySizeGT={partySizeGT}
              />
            )
          }),
          add_range_link = (
            <a
              href="javascript:void(0)"
              style={{ display: 'inline-block', paddingTop: '15px' }}
              onClick={this.onClickAddRangeHandler}
              data-test="sr-add-duration-range-link"
              id="add-duration-range-link"
            >
              + Add time range in the shift with different default durations
            </a>
          )
        this.updateLegacyTimeRangeDurationsKey(legacyTimeRangeDurationsKey)
        return (
          <div className="options">
            <p className="group-label">Duration: average turn time based on party size</p>
            {venue_settings.is_buffers_enabled && (
              <helpers.buffersInput
                media_url={media_url}
                interval_minutes={intervalMin}
                addBufferTime={addBufferTime}
                bufferMins={bufferMins}
                partySizeGT={partySizeGT}
                updateParent={v => this.setState(v)}
              />
            )}
            {duration_ranges}
            {add_range_link}
          </div>
        )
      },
    }),

    defaultTurnTimeRange: React.createClass({
      shouldComponentUpdate: function (nextProps, nextState) {
        return (
          _.size(this.props.start_time_options) !== _.size(nextProps.start_time_options) ||
          this.props.duration_dict !== nextProps.duration_dict ||
          this.props.start_time !== nextProps.start_time ||
          this.props.show_start_time !== nextProps.show_start_time ||
          this.props.showBuffer !== nextProps.showBuffer ||
          this.props.bufferMins !== nextProps.bufferMins ||
          this.props.partySizeGT !== nextProps.partySizeGT
        )
      },

      radioCssStyle: function () {
        return {
          inputCss: { position: 'relative', left: 0, float: 'left', border: 0 },
          preLabelStyle: { paddingTop: '10px' },
          preInputStyle: { float: 'left', width: 'auto', height: 'auto', paddingTop: 10 },
          postLabelStyle: { margin: 0, width: 300, paddingTop: 10, paddingLeft: 20, fontWeight: 500 },
        }
      },

      render: function () {
        const showBuffer = this.props.showBuffer
        const bufferMins = this.props.bufferMins
        const partySizeGT = this.props.partySizeGT
        var that = this,
          duration_dict = this.props.duration_dict,
          start_time = this.props.start_time,
          media_url = this.props.media_url,
          range_index = this.props.range_index,
          start_time_options = this.props.start_time_options,
          show_start_time = this.props.show_start_time,
          onClickRemoveRangeHandler = this.props.onClickRemoveRangeHandler,
          selectLabels = {
            '1 guest': 1,
            '2 guests': 2,
            '3 guests': 3,
            '4 guests': 4,
            '5 guests': 5,
            '6 guests': 6,
            '7 guests': 7,
            '8 guests': 8,
            '9 guests': 9,
            '10+ guests': -1,
          },
          nameLookup = {
            1: 'duration_1_' + range_index,
            2: 'duration_2_' + range_index,
            3: 'duration_3_' + range_index,
            4: 'duration_4_' + range_index,
            5: 'duration_5_' + range_index,
            6: 'duration_6_' + range_index,
            7: 'duration_7_' + range_index,
            8: 'duration_8_' + range_index,
            9: 'duration_9_' + range_index,
            '-1': 'duration_10_' + range_index,
          },
          legacy_time_range_durations_radio = (
            <modules.formElements.create
              type="radio"
              value={start_time}
              name={'legacy_time_range_durations_key'}
              className={'legacy_time_range_durations_key'}
              id={'legacy_time_range_durations_key_' + range_index}
              label={'Use as default for legacy mobile apps'}
              onClickHandler={this.props.onClickLegacyTimeRangeDuration}
              {...this.radioCssStyle()}
            />
          ),
          onChangeStartTime = function (e) {
            that.props.onChangeStartTime(that.props.range_index, e.target.value)
          },
          startTimeSelection = show_start_time ? (
            <div className="inline" style={{ width: '100%', marginBottom: 10 }}>
              <modules.formElements.create
                type="select"
                style={{ marginLeft: 8 }}
                inputCss={{ border: 0 }}
                value={range_index === 0 ? start_time_options[0] : start_time}
                className="duration_start_times"
                testId={`sr-select-duration-start-times-${range_index}`}
                name={'duration_start_time_' + range_index}
                disabled={range_index === 0}
                options={start_time_options}
                media_url={this.props.media_url}
                labelText={'Time range start'}
                onChangeHandler={onChangeStartTime}
              />

              {onClickRemoveRangeHandler && (
                <div style={{ marginTop: 37 }}>
                  <a
                    href="javascript:void(0)"
                    data-test={`sr-link-remove-time-range-${range_index}`}
                    onClick={function () {
                      onClickRemoveRangeHandler(range_index)
                    }}
                  >
                    Remove time range
                  </a>
                </div>
              )}
            </div>
          ) : (
            <input type="hidden" className="duration_start_times" name="duration_start_time_0" />
          ),
          timesSelection = _.map(
            selectLabels,
            function (duration_index, interval) {
              const bufferInfo = `+ ${bufferMins} MIN`
              const showBufferInfo = showBuffer && (duration_index == -1 || duration_index > (partySizeGT || 0)) && (bufferMins ?? 0) > 0
              var options = helpers.getAvgTurnaroundTime(),
                selectValue = duration_dict[duration_index],
                fieldName = nameLookup[duration_index],
                onChangeDuration = function (e) {
                  that.props.onChangeDuration(range_index, duration_index, parseInt(e.target.value, 10))
                }
              return (
                <div>
                  <modules.formElements.create
                    type="select"
                    className="duration-start-time"
                    value={selectValue}
                    key={fieldName}
                    options={options}
                    media_url={media_url}
                    id={fieldName}
                    labelText={interval}
                    name={fieldName}
                    onChangeHandler={onChangeDuration}
                    preLabelStyle={{
                      float: 'left',
                      marginTop: 0,
                      padding: '6px 8px',
                      marginRight: 5,
                      width: 100,
                    }}
                    preInputStyle={{ margin: '0px' }}
                    style={{ width: '200%', marginTop: 5 }}
                    inputCss={{
                      padding: '6px 8px',
                      marginLeft: 10,
                      border: 0,
                      backgroundPositionX: '60%',
                      backgroundPosition: '60%',
                    }}
                    labelStyle={{ display: 'flex' }}
                    postLabelText={showBufferInfo ? bufferInfo : null}
                    postLabelStyle={{ marginLeft: 10 }}
                  />
                </div>
              )
            },
            this
          )
        return (
          <div style={{ display: 'block' }}>
            {startTimeSelection}
            <div style={{ display: 'inline-block' }}>{timesSelection}</div>
            <div style={{ clear: 'both', paddingBottom: '40px', display: show_start_time ? 'block' : 'none' }}>
              {legacy_time_range_durations_radio}
            </div>
            <hr style={{ clear: 'both', border: '1px solid #CCCCCC' }} />
          </div>
        )
      },
    }),

    convertDurationDictRangesToPairs: function (durationDictRanges) {
      var durationDictRangePairs = _.pairs(durationDictRanges),
        allDayTimes = helpers.getAllDayTimes()
      durationDictRangePairs = _.sortBy(durationDictRangePairs, function (pair) {
        return _.indexOf(allDayTimes, pair[0])
      })
      return durationDictRangePairs
    },

    scheduleDateViewSelection: React.createClass({
      render: function () {
        return (
          <div className="inline group-size">
            <p className="group-label" style={{ margin: '10px 0px 3px' }}>
              When should the shift occur?
            </p>
            <div style={{ color: '#AAA', marginBottom: 10, marginTop: 10 }}>{this.props.date}</div>
            <input type="hidden" name="start_date" value={this.props.date} />
          </div>
        )
      },
    }),

    scheduleDaySelection: React.createClass({
      render: function () {
        return (
          <div className="inline group-size">
            <input id="schedule-day" type="hidden" value="2" className="auto -schedule_day" name="schedule_day" />
            <p className="group-label">
              Days to repeat this shift
              <span className="required">*</span>
            </p>
            <helpers.shiftDaySection
              selectedDays={this.props.selectedDays}
              addOverlayCallback={this.props.addOverlayCallback}
              startDay={this.props.startDay}
            />
          </div>
        )
      },
    }),

    pacingInput: React.createClass({
      getInitialState: function () {
        return {
          covers_per_seating_interval: this.props.coversPerSeatingInterval,
        }
      },

      onChangeHandler: function (e) {
        this.setState({ covers_per_seating_interval: $(e.target).val() })
      },

      render: function () {
        return (
          <span>
            <helpers.coversPerSeatingIntervalNumber
              covers_per_seating_interval={this.state.covers_per_seating_interval}
              onChangeHandler={this.onChangeHandler}
            />
            <helpers.customPacing
              media_url={this.props.media_url}
              max_pacing={this.state.covers_per_seating_interval}
              startTime={this.props.startTime}
              endTime={this.props.endTime}
              customPacing={this.props.customPacing}
              intervalMin={this.props.intervalMin}
            />
          </span>
        )
      },
    }),

    coversPerSeatingIntervalNumber: React.createClass({
      render: function () {
        return (
          <modules.formElements.create
            type="number"
            min="1"
            name="covers_per_seating_interval"
            labelText="Pacing: max numbers of covers per seating interval"
            preLabelStyle={{ float: 'left', paddingTop: 10 }}
            preInputStyle={{ clear: 'both' }}
            value={this.props.covers_per_seating_interval}
            style={{ fontFamily: 'Roboto' }}
            onChangeHandler={this.props.onChangeHandler}
          />
        )
      },
    }),

    coversMaxPerShiftInput: React.createClass({
      getInitialState: function () {
        return {
          hasCoversMaxPerShift: _.isNumber(this.props.coversMaxPerShift),
          coversMaxPerShift: this.props.coversMaxPerShift,
        }
      },

      onClickHandler: function (e) {
        this.setState({
          hasCoversMaxPerShift: $(e.target).is(':checked'),
          coversMaxPerShift: null,
        })
      },

      render: function () {
        return (
          <div className="inline">
            <p className="group-label">Set maximum total covers for shift</p>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                checked={this.state.hasCoversMaxPerShift ? true : false}
                name="has_covers_max_per_shift"
                id="has_covers_max_per_shift"
                label="&nbsp;"
                labelStyle={{ marginTop: 0 }}
                inputCss={{ border: 0 }}
                preInputStyle={{ backgroundColor: '#FFF', padding: 3, margin: 0 }}
                style={{ paddingLeft: 0 }}
                onClickHandler={this.onClickHandler}
              />
              <modules.formElements.create
                type="number"
                min="1"
                name="covers_max_per_shift"
                value={this.state.coversMaxPerShift}
                style={{ fontFamily: 'Roboto', display: this.state.hasCoversMaxPerShift ? 'block' : 'none' }}
              />
            </div>
          </div>
        )
      },
    }),

    allowDoubleBooking: React.createClass({
      render: function () {
        return (
          <div className="inline" style={{ height: 100 }}>
            <p className="group-label">Allow double booking on same tables</p>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                checked={this.props.double_booking ? true : false}
                name="double_booking"
                label="&nbsp;"
                labelStyle={{ marginTop: 0 }}
                id="allow_double_booking"
                inputCss={{ border: 0 }}
                preInputStyle={{ backgroundColor: '#FFF', padding: 3, margin: 0 }}
                style={{ paddingLeft: 0 }}
              />
            </div>
          </div>
        )
      },
    }),

    customPacingUnit: React.createClass({
      getInitialState: function () {
        return { selected: this.props.selected }
      },
      resetState: function (e) {
        this.setState({ selected: $(e.currentTarget).is(':checked') })
      },
      shouldComponentUpdate: function (nextProps, nextState) {
        return !this.state.selected || this.state.selected != nextState.selected
      },
      render: function () {
        return (
          <div className="inline" style={{ width: '100%', margin: 3 }}>
            <modules.formElements.create
              type="checkbox"
              name={'custom_pacing_time'}
              id={this.props.timeslot}
              value={this.props.timeslot}
              index={this.props.index}
              key={this.props.index}
              label={this.props.timeslot}
              checked={this.state.selected}
              onClickHandler={this.resetState}
              preLabelStyle={{ float: 'left', paddingTop: '10px' }}
              style={{ clear: 'initial', backgroundColor: '#FFF', margin: 3 }}
              preInputStyle={{ margin: 0, padding: 3 }}
              postLabelStyle={{ top: '-1px' }}
              labelStyle={{ margin: 0 }}
            />
            <modules.formElements.create
              type="number"
              index={this.props.index}
              key={'input' + this.props.index}
              value={this.props.pace !== undefined ? this.props.pace : this.props.max_pacing}
              disabled={!this.state.selected}
              name={'custom_pacing_value'}
              inputCss={{ width: 40, padding: '6px 8px' }}
              style={{ clear: 'initial', backgroundColor: '#FFF', float: 'right', fontFamily: 'Roboto' }}
            />
          </div>
        )
      },
    }),

    customPacing: React.createClass({
      getInitialState: function () {
        var startTime = this.props.startTime,
          endTime = this.props.endTime
        return {
          show: !_.isEmpty(this.props.customPacing),
          startTime: startTime,
          endTime: endTime,
          intervalMin: this.props.intervalMin,
        }
      },

      onClickHandler: function (e) {
        this.setState({
          show: !this.state.show,
        })
      },

      componentDidMount: function () {
        helpers.customPacingComponent = this
      },

      render: function () {
        var customPacingByTime = helpers.convertCustomPacingToTimePacing(this.props.customPacing, this.state.intervalMin),
          imageUri = this.props.media_url + 'images/icons/',
          shutImageUrl = 'url(' + imageUri + 'blue-arrow-right.png)',
          openImageUrl = 'url(' + imageUri + 'blue-arrow-down.png)',
          imageUrl = this.state.show ? openImageUrl : shutImageUrl,
          shiftItems = _.map(
            helpers.getShiftTimes(this.state.startTime, this.state.endTime, true, this.state.intervalMin),
            function (timeslot, index) {
              var selectedPace = customPacingByTime[timeslot]
              return (
                <helpers.customPacingUnit
                  max_pacing={this.props.max_pacing}
                  selected={selectedPace !== undefined}
                  pace={selectedPace}
                  index={index}
                  timeslot={timeslot}
                  key={index}
                />
              )
            },
            this
          )
        return (
          <div className="inline" style={{ marginTop: 10, marginBottom: 10 }}>
            <div style={{ width: 400, marginBottom: 3 }}>
              <a href="javascript:void(0)" data-test="sr-link-set-custom-interval" onClick={this.onClickHandler}>
                <div
                  style={{
                    float: 'left',
                    backgroundImage: imageUrl,
                    backgroundSize: 15,
                    width: 15,
                    marginTop: 1,
                    height: 14,
                  }}
                ></div>
                &nbsp;Or, set custom pacing per seating interval
              </a>
            </div>
            {this.state.show ? shiftItems : undefined}
          </div>
        )
      },
    }),

    shiftDateIntervals: React.createClass({
      componentDidUpdate: function () {
        this.postRenderUpdate()
      },

      postRenderUpdate: function () {
        helpers.applyCalendarBehavior('#shift-start-date-selector', this.props.startDay)
        helpers.applyCalendarBehavior(
          '#shift-end-date-selector',
          this.props.endDay ? this.props.endDay : this.props.startDay,
          undefined,
          this.props.startDay
        )
        $(this.getDOMNode()).find('#shift-start-date-selector').prop('disabled', this.props.isEditMode)
        $(this.getDOMNode())
          .find('#shift-end-date-selector')
          .prop('disabled', !this.props.endDateEditable || this.props.isIndefinite)
        $(this.getDOMNode()).find('#shift-start-date-selector-submit').prop('disabled', true)
        $(this.getDOMNode())
          .find('#shift-end-date-selector-submit')
          .prop('disabled', this.props.isIndefinite || !this.props.endDateEditable)
        $(this.getDOMNode()).find('.shift-indefinitely').toggle(this.props.isIndefinite)
        $(this.getDOMNode()).find('.shift-indefinitely').css({ display: 'block' })
        if (this.props.isIndefinite) {
          $('#shift-end-date-selector').val('--')
          $('#shift-end-date-selector-submit').val('--')
        }
      },

      componentDidMount: function () {
        this.postRenderUpdate()
      },

      onClickIndefinitely: function (e) {
        if ($(e.currentTarget).is(':checked')) {
          $('#shift-end-date-selector').prop('disabled', true)
          $('#shift-end-date-selector').val('--')
          $('#shift-end-date-selector-submit').prop('disabled', true)
          $('#shift-end-date-selector-submit').val('--')
        } else {
          $('#shift-end-date-selector').prop('disabled', false)
          $('#shift-end-date-selector-submit').prop('disabled', false)
        }
      },

      render: function () {
        return (
          <div>
            <div className="inline">
              <div className="form-element cal text">
                <p className="group-label">
                  Start date
                  <span className="required">*</span>
                </p>
                <label htmlFor="shift-date-selector">
                  <p className="input" style={{ position: 'relative' }}>
                    <input readOnly="true" id="shift-start-date-selector" type="text" name="start_date_cal" srValidate="datepicker_date" />
                    <input id="shift-start-date-selector-submit" type="hidden" name="start_date" />
                    <img src={this.props.calendar_image} />
                  </p>
                </label>
                <p className="validator" srValidateError>
                  Please select a start date
                </p>
              </div>
              <div className="form-element cal text">
                <p className="group-label">
                  End date
                  <span className="required">*</span>
                </p>
                <label htmlFor="shift-end-date-selector">
                  <p className="input" style={{ position: 'relative' }}>
                    <input readOnly="true" id="shift-end-date-selector" name="end_date_cal" type="text" srValidate="datepicker_date" />
                    <input id="shift-end-date-selector-submit" type="hidden" name="end_date" />
                    <img src={this.props.calendar_image} />
                  </p>
                </label>
                <p className="validator" srValidateError>
                  Please select a end date
                </p>
              </div>
            </div>
            <div className="inline">
              <modules.formElements.create
                type="checkbox"
                onClickHandler={this.onClickIndefinitely}
                checked={this.props.isIndefinite ? true : false}
                name="shift-indefinitely"
                label="continue indefinite"
                id="shift_indefinite"
                inputCss={{ border: 0 }}
                preInputStyle={{ backgroundColor: '#FFF' }}
                style={{ paddingLeft: 200 }}
              />
            </div>
          </div>
        )
      },
    }),

    convertToJSDate: function (date, forceMonthDay) {
      var newDate = new Date(date.replace(/-/g, '/'))
      var dateDisplay =
        forceMonthDay || Pmp.Utils.isMonthDayDateFormat()
          ? newDate.getMonth() + 1 + '/' + newDate.getDate()
          : newDate.getDate() + '/' + (newDate.getMonth() + 1)
      return dateDisplay + '/' + newDate.getFullYear()
    },

    propertiesSection: function (
      name,
      category,
      selectedDays,
      startDay,
      endDay,
      isIndefinite,
      startTime,
      endTime,
      calendar_image,
      media_url,
      shift,
      floorplans_data,
      addOverlayCallback,
      is_recurring,
      type,
      internal_cutoff_type,
      internal_cutoff_num,
      internal_cutoff_hour,
      isCloneMode,
      show_shift_reporting_periods=false,
      shift_reporting_periods=[],
    ) {
      var is_edit_mode = helpers.mode === 'edit'
      var modified_name = !shift.is_override && type === EDIT_TYPE_OVERRIDE ? name + ' ' + startDay : name,
        daySelection = _.contains([EDIT_TYPE_OVERRIDE], type) ? undefined : (
          <helpers.scheduleDaySelection selectedDays={selectedDays} startDay={startDay} addOverlayCallback={addOverlayCallback} />
        ),
        endDateEditable = !_.contains([EDIT_TYPE_OVERRIDE], type),
        dateSelection = _.contains([EDIT_TYPE_OVERRIDE], type) ? undefined : (
          <helpers.shiftDateIntervals
            startDay={startDay}
            endDay={endDay}
            isIndefinite={isIndefinite}
            calendar_image={calendar_image}
            endDateEditable={endDateEditable}
            isEditMode={is_edit_mode}
          />
        ),
        startTimeFormatted = Pmp.Utils.timeWithLocale(startTime)
      endTimeFormatted = Pmp.Utils.timeWithLocale(endTime)

      return (
        <div className="section">
          {<helpers.scheduleName value={modified_name} media_url={media_url} />}
          <div className="inline" style={{ width: "100%", marginBottom: "10px"}}>
            {!is_edit_mode ? <helpers.scheduleCategory is_edit={is_edit_mode} value={category} media_url={media_url} /> : undefined}
            {show_shift_reporting_periods && <helpers.shiftReportingPeriods shift_reporting_periods={shift_reporting_periods} is_edit={is_edit_mode} value={shift.shift_reporting_period_id} media_url={media_url}/>}
          </div>
          {dateSelection}
          {daySelection}
          <helpers.shiftTimeIntervals
            media_url={media_url}
            startTime={startTimeFormatted}
            endTime={endTimeFormatted}
            intervalMin={shift.interval_minutes || 15}
          />
          <helpers.floorPlanLayout media_url={media_url} layout_id={shift.floorplan_id} layouts={floorplans_data.floorplan_layouts} />
          <helpers.shiftArea areas={floorplans_data.seating_areas} is_edit={shift.persistent_id} selected_areas={shift.seating_area_ids} />
          <helpers.advanceBookingLimits
            media_url={media_url}
            internal_cutoff_num={internal_cutoff_num}
            internal_cutoff_type={internal_cutoff_type}
            internal_cutoff_hour={internal_cutoff_hour}
          />
          <helpers.lastSeatingTime endTime={endTimeFormatted} />
        </div>
      )
    },

    capacitySection: function (
      media_url,
      startTime,
      endTime,
      overbook_enforced_shift_party_size,
      min,
      max,
      duration_minutes_by_party_size,
      duration_dict_ranges,
      bufferMinutesByPartySize,
      coversMaxPerShift,
      coversPerSeatingInterval,
      double_booking,
      customPacing,
      interval_minutes,
      legacyTimeRangeDurationsKey
    ) {
      var startTimeDisplay = Pmp.Utils.timeWithLocale(startTime),
        endTimeDisplay = Pmp.Utils.timeWithLocale(endTime)
      return (
        <div className="section">
          <helpers.partiesSelection
            media_url={media_url}
            overbook_enforced_shift_party_size={overbook_enforced_shift_party_size}
            min={min}
            max={max}
          />
          <helpers.defaultTurnTime
            media_url={media_url}
            duration_minutes_by_party_size={duration_minutes_by_party_size}
            duration_dict_ranges={duration_dict_ranges}
            startTime={startTimeDisplay}
            endTime={endTimeDisplay}
            legacyTimeRangeDurationsKey={legacyTimeRangeDurationsKey}
            bufferMinutesByPartySize={bufferMinutesByPartySize}
            interval_minutes={interval_minutes}
          />
          <helpers.pacingInput
            media_url={media_url}
            startTime={startTimeDisplay}
            endTime={endTimeDisplay}
            intervalMin={interval_minutes}
            coversPerSeatingInterval={coversPerSeatingInterval}
            customPacing={customPacing}
          />
          <helpers.coversMaxPerShiftInput coversMaxPerShift={coversMaxPerShift} />
          <helpers.allowDoubleBooking double_booking={double_booking} />
        </div>
      )
    },

    onMountCallBack: function (date, mountedNode) {},

    customPacingKeysSorted: function (customPacing) {
      var sortOrdersToMinutes = _.map(Object.keys(customPacing), function (mins) {
        var minutesSinceMidnight = parseInt(mins, 10)
        var midnightSortOrder = minutesSinceMidnight / 15
        var sortOrder = midnightSortOrder - 4 * Pmp.Manager.Global.start_of_day_hour
        if (sortOrder < 0) {
          sortOrder = 95 + sortOrder // Wrap-around to end of day
        }
        return [sortOrder, mins]
      })
      sortOrdersToMinutes.sort()
      return _.map(sortOrdersToMinutes, function (som) {
        return som[1]
      })
    },

    convertCustomPacingToTimePacing: function (customPacing, intervalMin) {
      return _.object(
        _.map(
          customPacing,
          function (pacing, mins) {
            var intervalMin = intervalMin || 15,
              timeslot = modules.weekViewManager.getDisplayInterval(Math.floor(mins / 60), (mins % 60) / intervalMin)
            if (Pmp.Manager.Global._is_military_time) {
              timeslot = Pmp.Utils.toMilitaryTime(timeslot)
            }
            return [timeslot, pacing]
          },
          this
        )
      )
    },

    applyCalendarBehavior: function (divElement, selectedDate, callback, minDate) {
      var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
      var jsDate = $.datepicker.parseDate(dateFmt, selectedDate)
      $(divElement).datepicker({
        dateFormat: dateFmt,
        altField: divElement + '-submit',
        altFormat: 'mm/dd/yy',
        minDate: minDate,
        closeText: 'Cancel',
        firstDay: 0,
        parentObj: this,
        showButtonPanel: true,

        beforeShow: function () {
          $('#ui-datepicker-div').addClass('customize')
        },
        onSelect: function (dateText, selObj) {
          if (callback) {
            callback(dateText)
          }
        },
        prependZero: function (num) {
          return ('0' + num).slice(-2)
        },
      })
      $(divElement).datepicker('setDate', jsDate, true)
    },
  }

  var formatDate = function (date) {
    return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
  }

  var isValidParams = function (id) {
    var validator = new sr.Validator($('#' + id).parent(), 'USD')
    validator.activeFieldAttr = 'data-sr-validate'
    validator.errorDisplayAttr = 'data-sr-validate-error'
    isValid = validator.validate()
    var party_size_min = Number(
        $('#' + id)
          .find('[name=party_size_min]')
          .val()
      ),
      party_size_max = Number(
        $('#' + id)
          .find('[name=party_size_max]')
          .val()
      ),
      shift_category = $('#' + id)
        .find('[name=category]')
        .val(),
      interval_min = $('#' + id)
        .find('[name=interval_minutes]')
        .val()

    const seatingAreas = $(`#${id}`)
      .serializeArray()
      .filter(item => item.name === 'area_ids')
      .map(item => item.value)
    const isAllSeatingAreasChecked = $(`#${id} #seating-areas-all`).is(':checked')

    if (!isAllSeatingAreasChecked && seatingAreas.length === 0) {
      Interface._alert('Seating area selection required')
      isValid = false
    }

    if (party_size_max < party_size_min) {
      Interface._alert("Party size max shouldn't be less than min")
      isValid = false
    }
    if (shift_category === '') {
      Interface._alert('Shift category is a required field')
      isValid = false
    }
    if (interval_min === '') {
      Interface._alert('Time Interval is a required field')
      isValid = false
    }

    const addBufferTime = $('#' + id)
      .find('[name=add_buffer_time]')
      .is(':checked')
    const partySizeGT = $('#' + id)
      .find('[name=buffer_party_size_gt]')
      .val()
    if (addBufferTime && partySizeGT == null) {
      Interface._alert('Buffer party size cannot be blank')
      isValid = false
    }
    return isValid
  }

  var booking_policy_id = null
  var policy_type = null
  var cancellation_policy_id = null
  var cancellation_policy_type = null

  var changeBookingPolicyID = function (value) {
    booking_policy_id = value
  }
  var changePolicyType = function (value) {
    policy_type = value
  }
  var changeCancellationPolicyType = function (value) {
    cancellation_policy_type = value
  }
  var changeCancellationPolicyID = function (value) {
    cancellation_policy_id = value
  }

  var getShiftData = function (id, name, shiftId) {
    var serializedArray = $('#' + id).serializeArray(),
      custom_pacing_times = _.collect(_.where(serializedArray, { name: 'custom_pacing_time' }), 'value'),
      custom_pacing_values = _.collect(_.where(serializedArray, { name: 'custom_pacing_value' }), 'value'),
      custom_pacing_object = _.object(custom_pacing_times, custom_pacing_values),
      category = (_.findWhere($('form').serializeArray(), { name: 'category' }) || {}).value,
      disabledStartDate = $('#' + id)
        .find('input[name=start_date]')
        .is(':disabled')
        ? $('#' + id)
            .find('input[name=start_date]')
            .val()
        : undefined,
      disabledFirstDurationStartTime = $('#id_duration_start_time_0').val(),
      days = _.map(_.where($('#' + id).serializeArray(), { name: 'days' }), function (t) {
        return t.value
      }).join(',')

    const isAllSeatingAreasChecked = $(`#${id} #seating-areas-all`).is(':checked')
    let seatingAreasList = $(`#${id}`)
      .serializeArray()
      .filter(item => item.name === 'area_ids')
      .map(item => item.value)
    seatingAreasList = isAllSeatingAreasChecked ? [] : seatingAreasList
    const areas = seatingAreasList.join(',')

    serializedArray = _.reject(serializedArray, item => item.name === 'internal_cutoff_hour' && item.value === '0')

    const addBufferTime = $('#' + id)
      .find('[name=add_buffer_time]')
      .is(':checked')
    const buffer_minutes_by_party_size = addBufferTime
      ? JSON.stringify({
          [$('#' + id)
            .find('[name=buffer_party_size_gt]')
            .val()]: Number(
            $('#' + id)
              .find('[name=buffer_mins]')
              .val()
          ),
        })
      : null

    var add_upsells_flag = _.findWhere(serializedArray, { name: 'add_upsells' }).value
    var selected_upsells = add_upsells_flag === 'true' ? _.findWhere(serializedArray, { name: 'selected_upsells' }).value : ''

    serializedArray = _.reject(
      serializedArray,
      item => item.name === 'days' || item.name === 'selected_upsells' || item.name === 'duration_start_time_0'
    )
    serializedArray.push(
      {
        name: 'is_overbook_enforced_shift_party_size',
        value: $('#' + id)
          .find('input[name=overbook_enforced_shift_party_size]')
          .is(':checked'),
      },
      {
        name: 'allow_double_booking',
        value: $('#' + id)
          .find('input[name=double_booking]')
          .is(':checked'),
      },
      {
        name: 'custom_pacing',
        value: JSON.stringify(custom_pacing_object),
      },
      {
        name: 'venue',
        value: name,
      },
      {
        name: 'duration_start_time_0',
        value: disabledFirstDurationStartTime,
      },
      {
        name: 'booking_policy_id',
        value: booking_policy_id,
      },
      {
        name: 'policy_type',
        value: policy_type,
      },
      {
        name: 'cancellation_policy_id',
        value: cancellation_policy_id,
      },
      {
        name: 'cancellation_policy_type',
        value: cancellation_policy_type,
      }
    )

    if (shiftId) {
      serializedArray.push({
        name: 'shift_id',
        value: shiftId,
      })
    }

    if (selected_upsells) {
      serializedArray.push({
        name: 'selected_upsells',
        value: selected_upsells,
      })
    }

    if (!_.isEmpty(days)) {
      serializedArray.push({
        name: 'days',
        value: days,
      })
    }

    if (disabledStartDate) {
      serializedArray.push({
        name: 'start_date',
        value: disabledStartDate,
      })
    }

    if (areas && areas.length > 0) {
      serializedArray.push({
        name: 'areas',
        value: areas,
      })
    }

    if (category) {
      serializedArray.push({
        name: 'name',
        value: category,
      })
    }

    if (buffer_minutes_by_party_size) {
      serializedArray.push({
        name: 'buffer_minutes_by_party_size',
        value: buffer_minutes_by_party_size,
      })
    }

    return serializedArray
  }

  var addShift = function (id, name, event, seatingAreas) {
    if ($(event.currentTarget).hasClass('disabled')) {
      return
    }
    if (!isValidParams(id)) {
      return
    }

    displayReviewChangesModal({
      id,
      name,
      seatingAreas,
      onSave: () => {
        createNewShift(id, name)
      },
    })
  }

  const createNewShift = function (id, name) {
    $('.button.bottom').find('a').addClass('disabled')
    $.ajax({
      method: 'POST',
      url: '/api-yoa/shifts/add',
      data: $.param(getShiftData(id, name)),
      success: function (response) {
        if (response.status != '200') {
          Interface._alert(response.msg)
        } else {
          var start_date = $('#' + id)
            .find('[name=start_date]')
            .val()
          closeSlideout(id)
          modules.shiftWeekViewManager.refresh(start_date)
        }
      },
      error: function (jqXHR, textStatus, error) {
        var response = JSON.parse(jqXHR.responseText)
        Interface._alert(response.msg)
      },

      complete: function () {
        $('.button.bottom').find('a').removeClass('disabled')
      },
    })
  }

  const displayReviewChangesModal = function ({ id, name, seatingAreas, shift, type, selectedDay, onSave }) {
    $('.button.bottom').find('a').addClass('disabled')

    const recurring = type ? type === EDIT_TYPE_FOLLOWING || type === EDIT_TYPE_ALL : undefined
    const entireSeries = type ? type === EDIT_TYPE_ALL : undefined

    const shiftData = {}
    const shiftDataArray = getShiftData(id, name, shift && shift.persistent_id ? shift.persistent_id : undefined)
    shiftDataArray.forEach(({ name, value }) => {
      if (name === 'custom_pacing' || name === 'buffer_minutes_by_party_size') {
        shiftData[name] = JSON.parse(value)
      } else if (name === 'area_ids') {
        if (Array.isArray(shiftData[name])) {
          shiftData[name].push(value)
        } else {
          shiftData[name] = [value]
        }
      } else {
        shiftData[name] = value
      }
    })

    if (shiftData.area_ids && shiftData.area_ids.length === seatingAreas.length) {
      shiftData.area_ids = []
    }

    $.ajax({
      method: 'POST',
      url: `/api-yoa/manager/${window.globalInit.venueId}/activitylog/json`,
      contentType: 'application/json',
      data: JSON.stringify({
        category: 'SHIFTS',
        ...(shift && shift.id ? { entity_object_id: shift.id } : {}),
        new_data: {
          ...shiftData,
          ...(selectedDay === undefined ? {} : { from_date: selectedDay }),
          ...(recurring === undefined ? {} : { is_recurring: recurring }),
          ...(entireSeries === undefined ? {} : { entire_series: entireSeries }),
        },
      }),
      success: function (response) {
        if (response.status != '200') {
          Interface._alert(response.msg)
        } else {
          const elem = document.getElementById('shift-help-modal')
          window.SvrManager.ReviewChangesModal.render(elem, {
            name,
            changeLogs: response.data.changelog,
            onDiscard: () => closeSlideout(id),
            onSave: () => {
              onSave(response.data.changelog, recurring, entireSeries)
            },
          })
        }
      },
      error: function (jqXHR, textStatus, error) {
        response = JSON.parse(jqXHR.responseText)
        Interface._alert(response.msg)
        const elem = document.getElementById('shift-help-modal')
        window.SvrManager.ReviewChangesModal.render(elem, {
          name,
          changeLogs: [],
          onDiscard: () => closeSlideout(id),
          onSave: () => {
            onSave([], recurring, entireSeries)
          },
        })
      },
      complete: function () {
        $('.button.bottom').find('a').removeClass('disabled')
      },
    })
  }

  var recurringDecisionOverlay = function (text, selectedDay, endDay, name, onOverrideCallBk, onRecurringSomeCallBk, onRecurringAllCallBk) {
    const dateFormat = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
    const selectedDate = $.datepicker.parseDate('mm/dd/yy', selectedDay)
    const selectedDayIntl = $.datepicker.formatDate(dateFormat, selectedDate)

    const today = new Date()
    const todayIntl = $.datepicker.formatDate(dateFormat, today)

    const textInPast = text === 'Delete' ? 'deleted' : 'edited'
    const nameTruncated = name.substring(0, 18)
    const headerText = `${text} ${name} Shift`
    const additionalText = endDay ? `ending on ${endDay}` : ''
    const contentText = `This shift is a part of a series${additionalText ? ' ' + additionalText : ''}. What would you like to do?`
    const untilEndText = endDay ? ` <strong>until end on ${endDay}</strong>` : ``

    const overrideButtonText = text === 'Delete' ? `Delete ${selectedDayIntl} only` : `Override ${selectedDayIntl} only`
    const overrideDesc = `All other days in ${nameTruncated} will remain unchanged`
    const followingButtonText = `${text} from ${selectedDayIntl} onwards`
    const followingDesc = `${nameTruncated} will be ${textInPast} from <strong>selected date</strong> (${selectedDayIntl}) onwards${untilEndText}`
    const followingSubdesc = `*Single-day overrides will remain unchanged`
    const allButtonText = `${text} all days from today onwards`
    const allDesc = `${nameTruncated} will be ${textInPast} from <strong>today</strong> (${todayIntl}) onwards${untilEndText}`
    const allSubdesc = `*Single-day overrides will remain unchanged`

    const closeLink = $('<a/>').css({ cursor: 'pointer', float: 'right', fontSize: 20, marginTop: '-15px' }).html('&times;')
    const header = $("<div class='header' />")
      .css({
        height: '26px',
        padding: '5px',
        paddingLeft: 15,
        color: '#000',
        marginTop: '10px',
        fontSize: '15px',
        borderBottom: '1px solid lightgrey',
        textTransform: 'uppercase',
      })
      .html(
        $("<div class='text' style='float: left; max-width: 80%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis'/>").sext(
          headerText
        )
      )
      .append(closeLink)
    const bodyHeader = $("<div class='body-text' />")
      .css({
        height: '20px',
        marginTop: '10px',
        marginLeft: '15px',
        textAlign: 'left',
        color: 'black',
        fontSize: '12px',
      })
      .html(contentText)
    const bodyButton1 = $("<div class='body-button recurring-override-button' />").html(
      `<div class="button">${overrideButtonText}</div><div class="desc">${overrideDesc}</div>`
    )
    const bodyButton2 = $("<div class='body-button recurring-following-button' />").html(
      `<div class="button">${followingButtonText}</div><div class="desc2">${followingDesc}<br /><span>${followingSubdesc}</span></div>`
    )
    const bodyButton3 = $("<div class='body-button recurring-all-button' />").html(
      `<div class="button">${allButtonText}</div><div class="desc2">${allDesc}<br /><span>${allSubdesc}</span></div>`
    )
    const nevermind = $("<div class='footer' />").html("<div class='button'>Nevermind</div>")

    let body = $('<div/>').append(header).append(bodyHeader).append(bodyButton1)
    if (selectedDate.setHours(0, 0, 0, 0) !== today.setHours(0, 0, 0, 0)) {
      body = body.append(bodyButton2)
    }
    body = body.append(bodyButton3).append(nevermind)

    // Apply additional css
    $(body).find('.button').css({
      backgroundColor: '#EEE',
      width: '100px',
      padding: '10px 0px',
      cursor: 'pointer',
    })
    $(body).find('.body-button').css({ display: 'flex', flexDirection: 'row', marginRight: '15px', minHeight: '42px' })
    $(body).find('.body-button .button').css({ width: '200px', minWidth: '200px', height: '100%', marginLeft: '15px', color: '#666' })
    $(body).find('.body-button .desc2 span').css({ fontSize: '10px' })
    $(body).find('.footer .button').css({ float: 'right', marginRight: '10px', marginBottom: '10px' })
    $(body).find('.body-button').css({ overflow: 'hidden', paddingTop: '10px' })
    $(body).find('.body-button .desc').css({ textAlign: 'left', marginLeft: '10px', padding: '10px 0px', color: '#000' })
    $(body).find('.body-button .desc2').css({ textAlign: 'left', marginLeft: '10px', color: '#000' })
    $(body).find('.body-button, .footer').css({ fontSize: '12px', color: '#666' })
    $(body).find('.footer').css({ marginTop: '10px' })

    // Event handling
    var closeHandler = function (e) {
      $(e.currentTarget).closest('.blocker').remove()
    }
    $(closeLink).on('click', closeHandler)
    $(nevermind).find('.button').on('click', closeHandler)
    $(bodyButton1)
      .find('.button')
      .on('click', function (e) {
        onOverrideCallBk(e)
        closeHandler(e)
      })
    $(bodyButton2)
      .find('.button')
      .on('click', function (e) {
        onRecurringSomeCallBk(e)
        closeHandler(e)
      })
    $(bodyButton3)
      .find('.button')
      .on('click', function (e) {
        onRecurringAllCallBk(e)
        closeHandler(e)
      })

    return $("<div class='modal' />")
      .css({
        width: '600px',
        height: 'fit-content',
        position: 'fixed',
        top: '50%',
        'font-family': 'Roboto',
        'font-weight': 'normal',
        'font-style': 'normal',
        color: '#999',
        left: '50%',
        'margin-top': '-100px',
        'margin-left': '-150px',
        'background-color': '#FFFFFF',
        'border-radius': '2px',
        'text-align': 'center',
        'z-index': 1001,
      })
      .append(body)
  }

  var deleteDecisionBlock = function (id, name, recurring, shiftId, shift_name, selectedDay, startDay, endDay) {
    if (
      _.size(
        _.filter($('.button.bottom').find('a'), function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }

    if (!recurring) {
      deleteShift(id, name, shiftId, true, selectedDay)
    } else if (startDay === endDay) {
      // One day recurring shift, just delete entirely
      deleteShift(id, name, shiftId, EDIT_TYPE_ALL, selectedDay)
    } else {
      var blocker = $('.blocker').size()
        ? $('.blocker')
        : $("<div class='blocker'/>")
            .css({
              'z-index': 1000,
              position: 'fixed',
              width: '100%',
              height: '100%',
              background: 'rgba(1,1,1,0.5)',
              left: 0,
              top: 0,
            })
            .append(
              recurringDecisionOverlay(
                'Delete',
                selectedDay,
                endDay,
                shift_name,
                _.partial(deleteShift, id, name, shiftId, EDIT_TYPE_OVERRIDE, selectedDay),
                _.partial(deleteShift, id, name, shiftId, EDIT_TYPE_FOLLOWING, selectedDay),
                _.partial(deleteShift, id, name, shiftId, EDIT_TYPE_ALL, selectedDay)
              )
            )
            .appendTo('body')
    }
  }

  var editDecisionBlock = function (
    invokeEditSlideOut,
    recurring,
    selectedDay,
    name,
    startDay,
    endDay,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id,
    show_shift_reporting_periods,
    shift_reporting_periods
  ) {
    if (
      _.size(
        _.filter($('.button.bottom').find('a'), function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }

    if (!recurring) {
      invokeEditSlideOut(EDIT_TYPE_OVERRIDE, name, venue_default_booking_policy_id, venue_default_cancellation_policy_id, show_shift_reporting_periods, shift_reporting_periods)
    } else if (startDay === endDay) {
      // One day recurring shift, just edit entirely
      invokeEditSlideOut(EDIT_TYPE_ALL, name, venue_default_booking_policy_id, venue_default_cancellation_policy_id, show_shift_reporting_periods, shift_reporting_periods)
    } else {
      var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
      var selDt = $.datepicker.parseDate('mm/dd/yy', selectedDay)
      var selectedDayIntl = $.datepicker.formatDate(dateFmt, selDt)

      var blocker = $('.blocker').size()
        ? $('.blocker')
        : $("<div class='blocker'/>")
            .css({
              'z-index': 1000,
              position: 'fixed',
              width: '100%',
              height: '100%',
              background: 'rgba(1,1,1,0.5)',
              left: 0,
              top: 0,
            })
            .append(
              recurringDecisionOverlay(
                'Edit',
                selectedDay,
                endDay,
                name,
                _.partial(
                  invokeEditSlideOut,
                  EDIT_TYPE_OVERRIDE,
                  'OVERRIDE (' + name + ') - ' + selectedDayIntl,
                  venue_default_booking_policy_id,
                  venue_default_cancellation_policy_id,
                  show_shift_reporting_periods,
                  shift_reporting_periods
                ),
                _.partial(
                  invokeEditSlideOut,
                  EDIT_TYPE_FOLLOWING,
                  'EDIT (' + name + ') - FROM ' + selectedDayIntl + ' onwards',
                  venue_default_booking_policy_id,
                  venue_default_cancellation_policy_id,
                  show_shift_reporting_periods,
                  shift_reporting_periods
                ),
                _.partial(
                  invokeEditSlideOut,
                  EDIT_TYPE_ALL,
                  'EDIT (' + name + ') - ' + ' ALL DAYS',
                  venue_default_booking_policy_id,
                  venue_default_cancellation_policy_id,
                  show_shift_reporting_periods,
                  shift_reporting_periods
                )
              )
            )
            .appendTo('body')
    }
  }

  var cloneShift = function (
    id,
    venue_name,
    media_url,
    floorplans_data,
    shift,
    selectedDay,
    addOverlayHandler,
    onCloseClickHandler,
    is_recurring,
    payment_setup,
    tax_rate,
    tax_groups,
    currency_symbol,
    venue_policy,
    venue_cancellation_policy,
    upsell_categories,
    selected_upsells,
    can_save_card,
    default_service_charge,
    default_gratuity,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id,
    show_shift_reporting_periods=false,
    shift_reporting_periods=[]
  ) {
    onCloseClickHandler()
    var selectedDaysOfWeek = []
    if (shift.day_of_week) {
      for (var i = 0; i < shift.day_of_week.length; i++) {
        if (shift.day_of_week[i]) {
          selectedDaysOfWeek.push(helpers.weekDayAbbreviationsByIndex[i])
        }
      }
    }
    var todaysDate = Pmp.Manager.Global._venue_today_date
    var todaysDateFormatted = [todaysDate.getMonth() + 1, todaysDate.getDate(), todaysDate.getFullYear()].join('/')
    modules.shiftslideout.add(
      'flyout-form',
      venue_name,
      media_url,
      floorplans_data,
      ['Copy of', shift.name].join(' '),
      shift.category,
      selectedDaysOfWeek,
      todaysDateFormatted,
      shift.start_time_display,
      shift.end_time_display,
      _.partial(addOverlayHandler, id),
      _.partial(onCloseClickHandler, id),
      venue_policy,
      venue_cancellation_policy,
      payment_setup,
      tax_rate,
      tax_groups,
      currency_symbol,
      shift.internal_cutoff_type,
      shift.internal_cutoff_num,
      shift.internal_cutoff_hour_display,
      upsell_categories,
      can_save_card,
      shift,
      selected_upsells,
      default_service_charge,
      default_gratuity,
      venue_default_booking_policy_id,
      venue_default_cancellation_policy_id,
      show_shift_reporting_periods,
      shift_reporting_periods
    )
  }

  var closeSlideout = function (id) {
    if ($('#' + id).find('.close a')[0]) {
      $('#' + id)
        .find('.close a')[0]
        .click()
    }
  }

  var deleteShift = function (id, name, shiftId, type, selectedDay) {
    var recurring = type === EDIT_TYPE_FOLLOWING || type === EDIT_TYPE_ALL
    var entireSeries = type === EDIT_TYPE_ALL
    if (
      _.size(
        _.filter($('.button.bottom').find('a'), function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }
    $('.button.bottom').find('a').addClass('disabled')
    $.ajax({
      method: 'POST',
      url: '/api-yoa/shifts/delete',
      data: _.extend(
        {},
        {
          from_date: selectedDay,
          recurring: recurring,
          shift_id: shiftId,
          venue: name,
          entire_series: entireSeries,
        }
      ),
      success: function (response) {
        if (response.status != '200') {
          Interface._alert(response.msg)
        } else {
          closeSlideout(id)
          modules.shiftWeekViewManager.refresh(selectedDay)
        }
      },
      error: function (jqXHR, textStatus, error) {
        var response = JSON.parse(jqXHR.responseText)
        Interface._alert(response.msg)
      },

      complete: function () {
        $('.button.bottom').find('a').removeClass('disabled')
      },
    })
  }

  var submitShift = function (id, name, shift, type, selectedDay, seatingAreas) {
    if (
      _.size(
        _.filter($('.button.bottom').find('a'), function (x) {
          return $(x).hasClass('disabled')
        })
      )
    ) {
      return
    }
    if (!isValidParams(id)) {
      return
    }

    displayReviewChangesModal({
      id,
      name,
      seatingAreas,
      shift,
      type,
      selectedDay,
      onSave: (changeLogs, recurring, entireSeries) => {
        editShift(id, name, shift.persistent_id, selectedDay, recurring, entireSeries, changeLogs)
      },
    })
  }

  var editShift = function (id, name, shiftId, selectedDay, recurring, entireSeries, changeLogs) {
    $('.button.bottom').find('a').addClass('disabled')

    $.ajax({
      method: 'POST',
      url: '/api-yoa/shifts/edit',
      data:
        $.param(getShiftData(id, name, shiftId)) +
        '&' +
        'from_date=' +
        selectedDay +
        '&' +
        'is_recurring=' +
        recurring +
        '&' +
        'entire_series=' +
        entireSeries,
      success: function (response) {
        if (response.status != '200' || !response.data.shift) {
          Interface._alert(response.msg)
        } else {
          const finishSave = () => {
            closeSlideout(id)
            modules.shiftWeekViewManager.refresh(helpers.convertToJSDate(response.data.shift.effective_start_date, true))
          }
          if (recurring) {
            $.ajax({
              method: 'GET',
              url: '/api-yoa/shifts/overrides/list',
              data: {
                entity_id: response.data.shift.id,
                persistent_id: response.data.shift.persistent_id,
                venue_id: window.globalInit.venueId,
                start_date: response.data.shift.effective_start_date,
                end_date: response.data.shift.effective_end_date || undefined,
              },
              success: shiftOverrides => {
                if (shiftOverrides.data.overrides.length == 0) {
                  finishSave()
                  return
                }
                const elem = document.getElementById('shift-help-modal')
                window.SvrManager.MgrOverridesModal.render(elem, {
                  onClose: finishSave,
                  changeLogs: changeLogs,
                  shiftOverrides: shiftOverrides.data.overrides,
                })
              },
              error: function (jqXHR, textStatus, error) {
                response = JSON.parse(jqXHR.responseText)
                Interface._alert(response.msg)
              },
            })
          } else {
            finishSave()
          }
        }
      },
      error: function (jqXHR, textStatus, error) {
        response = JSON.parse(jqXHR.responseText)
        Interface._alert(response.msg)
      },

      complete: function () {
        $('.button.bottom').find('a').removeClass('disabled')
        CustomerSuccessTracker.trackAvailability('Edit Shift')
      },
    })
  }

  var minsToDisplayTime = function (total_mins) {
    if (Number(total_mins) === 0) {
      return '0 mins'
    }
    var mins = total_mins % 60,
      hrs = Math.floor(total_mins / 60),
      hrs_post_fix = hrs === 1 ? 'hr' : 'hrs',
      mins_post_fix = mins === 1 ? 'min' : 'mins',
      hrs_part = hrs ? hrs + ' ' + hrs_post_fix : '',
      mins_part = mins ? mins + ' ' + mins_post_fix : ''

    return hrs_part + ' ' + mins_part
  }

  var viewSlide = function (
    id,
    venue_name,
    media_url,
    floorplans_data,
    shift,
    selectedDay,
    addOverlayHandler,
    onCloseClickHandler,
    payment_setup,
    tax_rate,
    tax_groups,
    currency_symbol,
    venue_policy,
    venue_cancellation_policy,
    upsell_categories,
    selected_upsells,
    can_save_card,
    default_service_charge,
    default_gratuity,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id,
    show_shift_reporting_periods = false,
    shift_reporting_periods = []
  ) {
    var venue_settings = window.globalInit.venueSettings
    var shift_policy_type = shift.booking_policy_id ? shift.booking_policy_id : shift.policy_type
    var policy_type =
      (venue_default_booking_policy_id && shift_policy_type === 'default') || !shift_policy_type
        ? venue_default_booking_policy_id
        : shift_policy_type
    var calendar_image = media_url + 'images/icons/calendar-negative.png'
    var selected_layout =
      _.findWhere(floorplans_data.floorplan_layouts, { id: shift.floorplan_id }) ||
      _.findWhere(floorplans_data.floorplan_layouts, { layout_id: 'default' }) ||
      {}
    var selected_areas = _.filter(floorplans_data.seating_areas, function (x) {
      return _.indexOf(shift.seating_area_ids, x.id) > -1
    })
    var startDay = helpers.convertToJSDate(shift.effective_start_date)
    var endDay = shift.effective_end_date ? helpers.convertToJSDate(shift.effective_end_date) : undefined
    var is_recurring = !shift.is_override || !(startDay === endDay)
    var isSelectedDtPassed = new Date(selectedDay) < Pmp.Manager.Global._venue_today_date
    var duration_dict_range_pairs = helpers.convertDurationDictRangesToPairs(shift.duration_dict_ranges)
    var startTimeDisplayShift = Pmp.Utils.timeWithLocale(shift.start_time_display)
    var endTimeDisplayShift = Pmp.Utils.timeWithLocale(shift.end_time_display)
    var onEditMouseOver = function (e) {
      $(e.currentTarget).css({ 'background-color': '#e7e7e7' })
    }
    var onEditMouseOut = function (e) {
      $(e.currentTarget).css({ 'background-color': '#FFFFFF' })
    }
    var onOptionsClick = function (e) {
      $('#more-actions-options').css({ display: 'block' })
    }
    var invokeEditMode = _.partial(
      editSlide,
      id,
      venue_name,
      media_url,
      floorplans_data,
      shift,
      selectedDay,
      addOverlayHandler,
      onCloseClickHandler,
      is_recurring,
      payment_setup,
      tax_rate,
      tax_groups,
      currency_symbol,
      venue_policy,
      venue_cancellation_policy,
      upsell_categories,
      selected_upsells,
      can_save_card,
      default_service_charge,
      default_gratuity
    )
    var cloneShiftMethod = _.partial(
      cloneShift,
      id,
      venue_name,
      media_url,
      floorplans_data,
      shift,
      selectedDay,
      addOverlayHandler,
      onCloseClickHandler,
      is_recurring,
      payment_setup,
      tax_rate,
      tax_groups,
      currency_symbol,
      venue_policy,
      venue_cancellation_policy,
      upsell_categories,
      selected_upsells,
      can_save_card,
      default_service_charge,
      default_gratuity,
      venue_default_booking_policy_id,
      venue_default_cancellation_policy_id,
      show_shift_reporting_periods,
      shift_reporting_periods
    )
    var onCloseActivityLogModal = function () {
      window.SvrManager.MgrActivityLog.unmountActivityLogModal()
    }
    var onViewActivityLog = function () {
      window.SvrManager.MgrActivityLog.renderActivityLogModal({
        entityObjectId: shift.id,
        isActive: true,
        setIsActive: () => {},
        onClose: onCloseActivityLogModal,
        title: shift.name,
      })
    }
    modules.slideout.render(
      'view',
      [
        {
          content: function () {
            var effective_end_date_element = (
                <modules.slideout.viewModeElement identifier="End Date" value={endDay ? endDay : 'Indefinite'} />
              ),
              days_element = shift.is_override ? undefined : (
                <modules.slideout.viewModeElement
                  identifier="Days"
                  value={modules.weekViewManager.getSelectedDays(shift.day_of_week).join(', ')}
                />
              )

            var timeOptions = _.extend({}, { 0: 'reservation time' }, shift.internal_cutoff_type === 'HOURS' ? [] : helpers.getShiftTimes())
            var shiftInternalTypeDisplay = helpers.advanceBookingOptions[shift.internal_cutoff_type]
            var shiftInternalHourDisplay = timeOptions[shift.internal_cutoff_hour_display] || timeOptions['0']
            var bookingInternallyDisplay = [shift.internal_cutoff_num, shiftInternalTypeDisplay, 'of', shiftInternalHourDisplay].join(' ')

            return (
              <div className="section">
                <modules.slideout.viewModeElement identifier="Shift category" value={helpers.shiftCategoryOptions[shift.category]} />
                {
                  show_shift_reporting_periods && shift.shift_reporting_period_id && shift_reporting_periods.find(shift_reporting_period => shift_reporting_period.id === shift.shift_reporting_period_id) && (
                      <modules.slideout.viewModeElement identifier="Shift Reporting Periods Group" value={shift_reporting_periods.find(shift_reporting_period => shift_reporting_period.id === shift.shift_reporting_period_id).name} />
                    )
                }
                {days_element}
                <modules.slideout.viewModeElement identifier="Start Date" value={startDay} />
                {effective_end_date_element}
                <modules.slideout.viewModeElement identifier="Time" value={startTimeDisplayShift + ' - ' + endTimeDisplayShift} />
                <modules.slideout.viewModeElement identifier="Interval" value={shift.interval_minutes + ' minutes'} />
                <modules.slideout.viewModeElement identifier="Floor Plan layout" value={selected_layout.layout_id} />
                <modules.slideout.viewModeElement
                  identifier="Seating areas"
                  value={selected_areas.length > 0 ? selected_areas.map(seatingArea => seatingArea.name).join(', ') : 'All'}
                />
                {shift.internal_cutoff_type !== 'INDEFINITE' && (
                  <modules.slideout.viewModeElement identifier="Booking Internally" value={bookingInternallyDisplay} />
                )}
              </div>
            )
          },
        },
        {
          content: function () {
            return (
              <PaymentAndPolicyView
                currency_symbol={currency_symbol}
                require_credit_card={shift.require_credit_card}
                party_size_min={shift.cc_party_size_min}
                payment_rule={shift.cc_payment_rule}
                cost={shift.cc_cost}
                charge_type={shift.cc_charge_type}
                apply_tax_rate={shift.cc_apply_tax_rate}
                tax_groups={tax_groups}
                tax_group_id={shift.tax_group_id}
                gratuity={shift.cc_gratuity}
                apply_service_charge={shift.apply_service_charge}
                service_charge_type={shift.service_charge_type}
                service_charge={shift.service_charge}
                apply_gratuity_charge={shift.apply_gratuity_charge}
                gratuity_type={shift.gratuity_type}
                require_gratuity_charge={shift.require_gratuity_charge}
                cancellation_policy_type={shift.cancellation_policy_type}
                auto_charge_type={shift.auto_charge_type}
                auto_charge_amount={shift.auto_charge_amount}
                auto_charge_amount_in_cents={shift.auto_charge_amount_in_cents}
                auto_charge_amount_type={shift.auto_charge_amount_type}
                auto_cutoff_num={shift.auto_cutoff_num}
                auto_cutoff_type={shift.auto_cutoff_type}
                auto_cutoff_hour={shift.auto_cutoff_hour}
                auto_cutoff_hour_display={shift.auto_cutoff_hour_display}
                cancel_cutoff_num={shift.cancel_cutoff_num}
                cancel_cutoff_type={shift.cancel_cutoff_type}
                cancel_cutoff_hour={shift.cancel_cutoff_hour}
                cancel_cutoff_hour_display={shift.cancel_cutoff_hour_display}
                can_save_card={can_save_card}
                default_service_charge={default_service_charge}
                default_gratuity={default_gratuity}
                policy_type={policy_type}
                venue_id={window.globalInit.venueId}
                venue_settings={venue_settings}
              />
            )
          },
        },
        {
          content: function () {
            return (
              <div className="section" style={{ paddingTop: 30 }}>
                <modules.slideout.viewModeHeader label="Capacity" />
                <modules.slideout.viewModeElement
                  identifier="Enforce Party Size on Internal Bookings"
                  value={shift.overbook_enforced_shift_party_size ? 'Yes' : 'No'}
                />
                <modules.slideout.viewModeElement identifier="Party size min" value={shift.party_min} />
                <modules.slideout.viewModeElement identifier="Party size max" value={shift.party_max} />
                <modules.slideout.viewModeElement
                  identifier="Maximum total covers for shift"
                  value={_.isNumber(shift.covers_max_per_shift) ? shift.covers_max_per_shift : 'No limit'}
                />
                <modules.slideout.viewModeElement identifier="Allow double booking" value={shift.double_booking ? 'Yes' : 'No'} />
              </div>
            )
          },
        },
        {
          content: function () {
            var render_duration_dict = function (duration_dict, label) {
                return (
                  <div>
                    {label && <modules.slideout.viewModeSubHeader label={label} />}
                    <modules.slideout.viewModeElement identifier="1 guest" value={minsToDisplayTime(duration_dict[1])} />
                    <modules.slideout.viewModeElement identifier="2 guests" value={minsToDisplayTime(duration_dict[2])} />
                    <modules.slideout.viewModeElement identifier="3 guests" value={minsToDisplayTime(duration_dict[3])} />
                    <modules.slideout.viewModeElement identifier="4 guests" value={minsToDisplayTime(duration_dict[4])} />
                    <modules.slideout.viewModeElement identifier="5 guests" value={minsToDisplayTime(duration_dict[5])} />
                    <modules.slideout.viewModeElement identifier="6 guests" value={minsToDisplayTime(duration_dict[6])} />
                    <modules.slideout.viewModeElement identifier="7 guests" value={minsToDisplayTime(duration_dict[7])} />
                    <modules.slideout.viewModeElement identifier="8 guests" value={minsToDisplayTime(duration_dict[8])} />
                    <modules.slideout.viewModeElement identifier="9 guests" value={minsToDisplayTime(duration_dict[9])} />
                    <modules.slideout.viewModeElement identifier="10+ guests" value={minsToDisplayTime(duration_dict[-1])} />
                  </div>
                )
              },
              additional_durations_rendered = _.map(duration_dict_range_pairs, function (pair) {
                var range_start_time = pair[0],
                  duration_dict = pair[1]
                return render_duration_dict(duration_dict, `From ${range_start_time} on:`)
              })
            return (
              <div className="section" style={{ paddingTop: 30 }}>
                <modules.slideout.viewModeHeader label="Duration" />
                {{ additional_durations_rendered }}
              </div>
            )
          },
        },
        {
          content: function () {
            var customPacingKeysSorted = helpers.customPacingKeysSorted(shift.custom_pacing)
            var customPacing = _.isEmpty(shift.custom_pacing)
              ? undefined
              : _.map(
                  customPacingKeysSorted,
                  function (mins) {
                    return (
                      <modules.slideout.viewModeElement
                        identifier={modules.weekViewManager.getMinutesDisplay(mins)}
                        value={shift.custom_pacing[mins]}
                      />
                    )
                  },
                  this
                )
            return (
              <div className="section" style={{ paddingTop: 30 }}>
                <modules.slideout.viewModeHeader label="Pacing" />
                <modules.slideout.viewModeElement identifier="Covers per interval" value={shift.covers_per_seating_interval} />
                {customPacing}
              </div>
            )
          },
        },
        {
          content: function () {
            var upsellLabels = _.reduce(
              selected_upsells,
              (result, upsell_category_id) => {
                if (upsell_categories[upsell_category_id]) {
                  var upsellName = upsell_categories[upsell_category_id].label
                  if (upsellName) {
                    return result.concat(
                      <modules.slideout.viewModeElement identifier="Upgrade Category" value={upsell_categories[upsell_category_id].label} />
                    )
                  }
                }
                return result
              },
              []
            )
            return (
              <div className="section" style={{ paddingTop: 30 }}>
                <modules.slideout.viewModeHeader label="Perks & Upgrades" />
                {upsellLabels.length > 0 ? upsellLabels : <modules.slideout.viewModeElement identifier="No upgrades selected" />}
              </div>
            )
          },
        },
      ],
      shift.name,
      <div>
        {isSelectedDtPassed ? (
          <p
            className="button bottom"
            style={{
              width: '100%',
              padding: 0,
              color: '#888',
            }}
          >
            <a
              onClick={onOptionsClick}
              onMouseOver={onEditMouseOver}
              onMouseOut={onEditMouseOut}
              style={{
                float: 'right',
                width: '15%',
                backgroundColor: '#FFF',
                color: '#000',
                padding: 0,
                borderRadius: 0,
                borderLeft: '1px solid #eee',
              }}
            >
              <span>...</span>
            </a>
            <ul
              id="more-actions-options"
              style={{
                position: 'absolute',
                right: '0px',
                bottom: '35px',
                display: 'none',
                zIndex: '1',
                backgroundColor: '#FFF',
                cursor: 'pointer',
                border: '1px solid #ccc',
              }}
            >
              <li
                onClick={cloneShiftMethod}
                onMouseOver={onEditMouseOver}
                onMouseOut={onEditMouseOut}
                style={{
                  padding: '10px 40px',
                  listStyleType: 'none',
                  cursor: 'pointer',
                }}
              >
                Clone shift
              </li>
              <li
                onClick={onViewActivityLog}
                onMouseOver={onEditMouseOver}
                onMouseOut={onEditMouseOut}
                style={{
                  padding: '10px 40px',
                  listStyleType: 'none',
                  cursor: 'pointer',
                }}
              >
                View Activity log
              </li>
            </ul>
          </p>
        ) : (
          <p
            className="button bottom"
            style={{
              width: '100%',
              padding: 0,
              color: '#888',
            }}
          >
            <a
              onClick={_.partial(
                editDecisionBlock,
                invokeEditMode,
                is_recurring,
                selectedDay,
                shift.name,
                startDay,
                endDay,
                venue_default_booking_policy_id,
                venue_default_cancellation_policy_id,
                show_shift_reporting_periods,
                shift_reporting_periods
              )}
              onMouseOver={onEditMouseOver}
              onMouseOut={onEditMouseOut}
              style={{
                float: 'left',
                width: '42%',
                backgroundColor: '#FFF',
                color: '#000',
                padding: 0,
              }}
            >
              <span>Edit</span>
            </a>
            <a
              onClick={_.partial(
                deleteDecisionBlock,
                id,
                venue_name,
                is_recurring,
                shift.persistent_id,
                shift.name,
                selectedDay,
                startDay,
                endDay
              )}
              onMouseOver={onEditMouseOver}
              onMouseOut={onEditMouseOut}
              style={{
                float: 'left',
                width: '42%',
                backgroundColor: '#FFF',
                color: '#000',
                padding: 0,
                borderRadius: 0,
                borderLeft: '1px solid #eee',
              }}
            >
              <span>Delete</span>
            </a>
            <a
              onClick={onOptionsClick}
              onMouseOver={onEditMouseOver}
              onMouseOut={onEditMouseOut}
              style={{
                float: 'left',
                width: '15%',
                backgroundColor: '#FFF',
                color: '#000',
                padding: 0,
                borderRadius: 0,
                borderLeft: '1px solid #eee',
              }}
            >
              <span>...</span>
            </a>
            <ul
              id="more-actions-options"
              style={{
                position: 'absolute',
                right: '0px',
                bottom: '35px',
                display: 'none',
                zIndex: '1',
                backgroundColor: '#FFF',
                cursor: 'pointer',
                border: '1px solid #ccc',
              }}
            >
              <li
                onClick={cloneShiftMethod}
                onMouseOver={onEditMouseOver}
                onMouseOut={onEditMouseOut}
                style={{
                  padding: '10px 40px',
                  listStyleType: 'none',
                  cursor: 'pointer',
                }}
              >
                Clone shift
              </li>
              <li
                onClick={onViewActivityLog}
                onMouseOver={onEditMouseOver}
                onMouseOut={onEditMouseOut}
                style={{
                  padding: '10px 40px',
                  listStyleType: 'none',
                  cursor: 'pointer',
                }}
              >
                View Activity log
              </li>
            </ul>
          </p>
        )}
      </div>,
      id,
      media_url,
      _.partial(helpers.onMountCallBack, startDay),
      _.partial(helpers.onMountCallBack, startDay),
      onCloseClickHandler
    )
  }

  var getDefaultDurationDictRanges = function (startTimeFormatted) {
    var default_duration_dict_ranges = {}
    default_duration_dict_ranges[startTimeFormatted] = { 1: 90, 2: 90, 3: 120, 4: 120, 5: 150, 6: 150, 7: 180, 8: 180, 9: 210, '-1': 210 }
    return default_duration_dict_ranges
  }

  var addSlide = function (
    id,
    venue_name,
    media_url,
    floorplans_data,
    name,
    category,
    selectedDays,
    selectedDay,
    startTime,
    endTime,
    addOverlayHandler,
    onCloseClickHandler,
    default_policy,
    default_cancellation_policy,
    payment_setup,
    tax_rate,
    tax_groups,
    currency_symbol,
    internal_cutoff_type,
    internal_cutoff_num,
    internal_cutoff_hour,
    upsell_categories,
    can_save_card,
    existing_shift,
    selected_upsells,
    default_service_charge,
    default_gratuity,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id,
    show_shift_reporting_periods = false,
    shift_reporting_periods = []
  ) {
    var startDay = selectedDay,
      endDay = startDay,
      isIndefinite = true

    var dt = $.datepicker.parseDate('mm/dd/yy', startDay)
    var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
    var dtIntl = $.datepicker.formatDate(dateFmt, dt)
    var enddt = $.datepicker.parseDate('mm/dd/yy', endDay)
    var enddtIntl = $.datepicker.formatDate(dateFmt, enddt)

    var upsell_categories = _.assign({}, upsell_categories)

    helpers.mode = 'add'
    var calendar_image = media_url + 'images/icons/calendar-negative.png'

    var use_clone_props = _.isEqual(existing_shift, {}) ? false : true
    var overbook_enforced_shift_party_size = use_clone_props ? existing_shift.overbook_enforced_shift_party_size : true
    var party_min = use_clone_props ? existing_shift.party_min : 1
    var party_max = use_clone_props ? existing_shift.party_max : 10
    var duration_minutes_by_party_size = use_clone_props
      ? existing_shift.duration_minutes_by_party_size
      : { 1: 90, 2: 90, 3: 120, 4: 120, 5: 150, 6: 150, 7: 180, 8: 180, 9: 210, '-1': 210 }
    var startTimeFormatted = Pmp.Utils.timeWithLocale(startTime)
    var default_duration_dict_ranges = getDefaultDurationDictRanges(startTimeFormatted)
    var duration_dict_ranges = use_clone_props ? existing_shift.duration_dict_ranges : default_duration_dict_ranges
    var legacy_time_range_durations_key = use_clone_props ? existing_shift.legacy_time_range_durations_key : startTimeFormatted
    var buffer_minutes_by_party_size = use_clone_props ? existing_shift.buffer_minutes_by_party_size : {}
    var covers_max_per_shift = use_clone_props ? existing_shift.covers_max_per_shift : null
    var covers_per_seating_interval = use_clone_props ? existing_shift.covers_per_seating_interval : 100
    var double_booking = use_clone_props ? existing_shift.double_booking : false
    var custom_pacing = use_clone_props ? existing_shift.custom_pacing : {}

    var cc_party_size_min = use_clone_props ? existing_shift.cc_party_size_min : null
    var cc_cost = use_clone_props ? existing_shift.cc_cost : null
    var cc_charge_type = use_clone_props ? existing_shift.cc_charge_type : null
    var cc_apply_tax_rate = use_clone_props ? existing_shift.cc_apply_tax_rate : null
    var cc_gratuity = use_clone_props ? existing_shift.cc_gratuity : null
    var apply_service_charge = use_clone_props ? existing_shift.apply_service_charge : null
    var service_charge_type = use_clone_props ? existing_shift.service_charge_type : 'DEFAULT_SERVICE_CHARGE'
    var service_charge = use_clone_props ? existing_shift.service_charge : null
    var apply_gratuity_charge = use_clone_props ? existing_shift.apply_gratuity_charge : null
    var gratuity_type = use_clone_props ? existing_shift.gratuity_type : 'DEFAULT_GRATUITY'
    var require_gratuity_charge = use_clone_props ? existing_shift.require_gratuity_charge : null
    var tax_group_id = use_clone_props ? existing_shift.tax_group_id : null
    var auto_charge_type = use_clone_props ? existing_shift.auto_charge_type : null
    var auto_charge_amount = use_clone_props ? existing_shift.auto_charge_amount : null
    var auto_charge_amount_in_cents = use_clone_props ? existing_shift.auto_charge_amount_in_cents : null
    var auto_charge_amount_type = use_clone_props ? existing_shift.auto_charge_amount_type : null
    var auto_cutoff_num = use_clone_props ? existing_shift.auto_cutoff_num : null
    var auto_cutoff_type = use_clone_props ? existing_shift.auto_cutoff_type : null
    var auto_cutoff_hour = use_clone_props ? existing_shift.auto_cutoff_hour : null
    var auto_cutoff_hour_display = use_clone_props ? existing_shift.auto_cutoff_hour_display : null
    var cancel_cutoff_num = use_clone_props ? existing_shift.cancel_cutoff_num : null
    var cancel_cutoff_type = use_clone_props ? existing_shift.cancel_cutoff_type : null
    var cancel_cutoff_hour = use_clone_props ? existing_shift.cancel_cutoff_hour : null
    var cancel_cutoff_hour_display = use_clone_props ? existing_shift.cancel_cutoff_hour_display : null
    var require_credit_card = use_clone_props ? existing_shift.require_credit_card : false
    var payment_rule = use_clone_props ? existing_shift.cc_payment_rule : null

    var policy = use_clone_props ? existing_shift.policy : null
    var default_policy_type = venue_default_booking_policy_id ? venue_default_booking_policy_id : 'default'
    var existing_policy_type = existing_shift.booking_policy_id ? existing_shift.booking_policy_id : existing_shift.policy_type
    var default_cancellation_policy_type = venue_default_cancellation_policy_id ? venue_default_cancellation_policy_id : 'default'
    var existing_cancellation_policy_type = existing_shift.payment_policy_id
      ? existing_shift.payment_policy_id
      : existing_shift.cancellation_policy_type
    var _policy_type = use_clone_props ? existing_policy_type : default_policy_type
    var policy_type =
      (venue_default_booking_policy_id && _policy_type === 'default') || !_policy_type ? venue_default_booking_policy_id : _policy_type
    var cancellation_policy = use_clone_props ? existing_shift.cancellation_policy : null
    var _cancellation_policy_type = use_clone_props ? existing_cancellation_policy_type : default_cancellation_policy_type
    var cancellation_policy_type =
      (venue_default_cancellation_policy_id && _cancellation_policy_type === 'default') || !_cancellation_policy_type
        ? venue_default_cancellation_policy_id
        : _cancellation_policy_type

    var selectedUpsells = use_clone_props ? selected_upsells : []
    modules.slideout.render(
      'edit',
      [
        {
          label: '1. Properties',
          name: 'properties',
          content: _.partial(
            helpers.propertiesSection,
            name,
            category,
            selectedDays,
            dtIntl,
            enddtIntl,
            isIndefinite,
            startTime,
            endTime,
            calendar_image,
            media_url,
            existing_shift,
            floorplans_data,
            addOverlayHandler,
            true,
            null,
            internal_cutoff_type,
            internal_cutoff_num,
            internal_cutoff_hour,
            (isCloneMode = !!use_clone_props),
            show_shift_reporting_periods,
            shift_reporting_periods
          ),
        },
        {
          label: '2. Capacity Settings',
          name: 'capacity-settings',
          content: _.partial(
            helpers.capacitySection,
            media_url,
            startTime,
            endTime,
            overbook_enforced_shift_party_size,
            party_min,
            party_max,
            duration_minutes_by_party_size,
            duration_dict_ranges,
            buffer_minutes_by_party_size,
            covers_max_per_shift,
            covers_per_seating_interval,
            double_booking,
            custom_pacing,
            existing_shift.interval_minutes,
            legacy_time_range_durations_key
          ),
        },
        {
          label: '3. Payment & Policy Settings',
          name: 'payment-policy-settings',
          content: function () {
            return (
              <PaymentAndPolicy
                media_url={media_url}
                payment_setup={payment_setup}
                default_policy={default_policy}
                default_cancellation_policy={default_cancellation_policy}
                tax_rate={tax_rate}
                tax_groups={tax_groups}
                tax_group_id={tax_group_id}
                currency_symbol={currency_symbol}
                initial_require_credit_card={require_credit_card}
                initial_payment_rule={payment_rule}
                initial_policy_type={policy_type}
                initial_policy={policy}
                initial_cancellation_policy_type={cancellation_policy_type}
                initial_cancellation_policy={cancellation_policy}
                can_save_card={can_save_card}
                initial_party_size_min={cc_party_size_min}
                initial_cost={cc_cost}
                initial_charge_type={cc_charge_type}
                initial_apply_tax_rate={cc_apply_tax_rate}
                initial_gratuity={cc_gratuity}
                apply_service_charge={apply_service_charge}
                service_charge_type={service_charge_type}
                service_charge={service_charge}
                apply_gratuity_charge={apply_gratuity_charge}
                gratuity_type={gratuity_type}
                require_gratuity_charge={require_gratuity_charge}
                auto_charge_type={auto_charge_type}
                auto_charge_amount={auto_charge_amount}
                auto_charge_amount_in_cents={auto_charge_amount_in_cents}
                auto_charge_amount_type={auto_charge_amount_type}
                auto_cutoff_num={auto_cutoff_num}
                auto_cutoff_type={auto_cutoff_type}
                auto_cutoff_hour={auto_cutoff_hour}
                auto_cutoff_hour_display={auto_cutoff_hour_display}
                cancel_cutoff_num={cancel_cutoff_num}
                cancel_cutoff_type={cancel_cutoff_type}
                cancel_cutoff_hour={cancel_cutoff_hour}
                cancel_cutoff_hour_display={cancel_cutoff_hour_display}
                default_service_charge={default_service_charge}
                default_gratuity={default_gratuity}
                venue_settings={window.globalInit.venueSettings}
                venue_id={window.globalInit.venueId}
                changeBookingPolicyID={changeBookingPolicyID}
                changePolicyType={changePolicyType}
                changeCancellationPolicyID={changeCancellationPolicyID}
                changeCancellationPolicyType={changeCancellationPolicyType}
              />
            )
          },
        },
        {
          label: '4. Perks & Upgrades',
          name: 'perks-upgrades',
          content: function () {
            return <PerksAndUpsells selectedUpsells={selectedUpsells} upsellCategories={upsell_categories} section="SHIFT" />
          },
        },
      ],
      'Add Shift',
      <div className="button bottom" style={{ padding: '1vh', borderTop: '1px solid #CACACA', color: '#888' }}>
        <a style={{ maxHeight: '38px' }} data-test="sr-button-add-shift" onClick={_.partial(addShift, id, venue_name)}>
          <span>Review Changes</span>
        </a>
      </div>,
      id,
      media_url,
      _.partial(helpers.onMountCallBack, dtIntl),
      _.partial(helpers.onMountCallBack, dtIntl),
      onCloseClickHandler
    )
  }

  var editSlide = function (
    id,
    venue_name,
    media_url,
    floorplans_data,
    shift,
    selectedDay,
    addOverlayHandler,
    onCloseClickHandler,
    is_recurring,
    payment_setup,
    tax_rate,
    tax_groups,
    currency_symbol,
    venue_policy,
    venue_cancellation_policy,
    upsell_categories,
    selected_upsells,
    can_save_card,
    default_service_charge,
    default_gratuity,
    type,
    headerLabel,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id,
    show_shift_reporting_periods=false,
    shift_reporting_periods=[]
  ) {
    var dt = $.datepicker.parseDate('mm/dd/yy', selectedDay)
    var dateFmt = Pmp.Utils.dateFormat(Pmp.Manager.Global._locale)
    var dtIntl = $.datepicker.formatDate(dateFmt, dt)

    var upsell_categories = _.assign({}, upsell_categories)

    var calendar_image = media_url + 'images/icons/calendar-negative.png',
      startDay = type === EDIT_TYPE_ALL ? helpers.convertToJSDate(shift.effective_start_date) : dtIntl,
      startTimeDisplay = shift.start_time_display,
      endTimeDisplay = shift.end_time_display,
      endDay = shift.effective_end_date ? helpers.convertToJSDate(shift.effective_end_date) : undefined
    helpers.mode = 'edit'
    var _policy_type = shift.booking_policy_id ? shift.booking_policy_id : shift.policy_type
    var policy_type =
      (venue_default_booking_policy_id && _policy_type === 'default') || !_policy_type ? venue_default_booking_policy_id : _policy_type
    var _cancellation_policy_type = shift.payment_policy_id ? shift.payment_policy_id : shift.cancellation_policy_type
    var cancellation_policy_type =
      (venue_default_cancellation_policy_id && _cancellation_policy_type === 'default') || !_cancellation_policy_type
        ? venue_default_cancellation_policy_id
        : _cancellation_policy_type

    modules.slideout.render(
      'edit',
      [
        {
          label: '1. Properties',
          name: 'properties',
          content: _.partial(
            helpers.propertiesSection,
            shift.name,
            shift.category,
            modules.weekViewManager.getSelectedDays(shift.day_of_week),
            startDay,
            endDay,
            !endDay,
            startTimeDisplay,
            endTimeDisplay,
            calendar_image,
            media_url,
            shift,
            floorplans_data,
            addOverlayHandler,
            is_recurring,
            type,
            shift.internal_cutoff_type,
            shift.internal_cutoff_num,
            shift.internal_cutoff_hour_display,
            false,
            show_shift_reporting_periods,
            shift_reporting_periods
          ),
        },
        {
          label: '2. Capacity Settings',
          name: 'capacity-settings',
          content: _.partial(
            helpers.capacitySection,
            media_url,
            startTimeDisplay,
            endTimeDisplay,
            shift.overbook_enforced_shift_party_size,
            shift.party_min,
            shift.party_max,
            shift.duration_minutes_by_party_size,
            shift.duration_dict_ranges,
            shift.buffer_minutes_by_party_size,
            shift.covers_max_per_shift,
            shift.covers_per_seating_interval,
            shift.double_booking,
            shift.custom_pacing,
            shift.interval_minutes,
            shift.legacy_time_range_durations_key
          ),
        },
        {
          label: '3. Payment & Policy Settings',
          name: 'payment-policy-settings',
          content: function () {
            return (
              <PaymentAndPolicy
                media_url={media_url}
                payment_setup={payment_setup}
                default_policy={venue_policy}
                default_cancellation_policy={venue_cancellation_policy}
                tax_rate={tax_rate}
                tax_groups={tax_groups}
                tax_group_id={shift.tax_group_id}
                currency_symbol={currency_symbol}
                initial_require_credit_card={shift.require_credit_card}
                initial_payment_rule={shift.cc_payment_rule}
                initial_policy_type={policy_type}
                initial_policy={shift.policy}
                initial_cancellation_policy_type={cancellation_policy_type}
                initial_cancellation_policy={shift.cancellation_policy}
                can_save_card={can_save_card}
                initial_party_size_min={shift.cc_party_size_min}
                initial_cost={shift.cc_cost}
                initial_charge_type={shift.cc_charge_type}
                initial_apply_tax_rate={shift.cc_apply_tax_rate}
                initial_gratuity={shift.cc_gratuity}
                apply_service_charge={shift.apply_service_charge}
                service_charge_type={shift.service_charge_type}
                service_charge={shift.service_charge}
                apply_gratuity_charge={shift.apply_gratuity_charge}
                gratuity_type={shift.gratuity_type}
                require_gratuity_charge={shift.require_gratuity_charge}
                policy_type={policy_type}
                auto_charge_type={shift.auto_charge_type}
                auto_charge_amount={shift.auto_charge_amount}
                auto_charge_amount_in_cents={shift.auto_charge_amount_in_cents}
                auto_charge_amount_type={shift.auto_charge_amount_type}
                auto_cutoff_num={shift.auto_cutoff_num}
                auto_cutoff_type={shift.auto_cutoff_type}
                auto_cutoff_hour={shift.auto_cutoff_hour}
                auto_cutoff_hour_display={shift.auto_cutoff_hour_display}
                cancel_cutoff_num={shift.cancel_cutoff_num}
                cancel_cutoff_type={shift.cancel_cutoff_type}
                cancel_cutoff_hour={shift.cancel_cutoff_hour}
                cancel_cutoff_hour_display={shift.cancel_cutoff_hour_display}
                default_service_charge={default_service_charge}
                default_gratuity={default_gratuity}
                venue_settings={window.globalInit.venueSettings}
                venue_id={window.globalInit.venueId}
                changeBookingPolicyID={changeBookingPolicyID}
                changePolicyType={changePolicyType}
                changeCancellationPolicyID={changeCancellationPolicyID}
                changeCancellationPolicyType={changeCancellationPolicyType}
              />
            )
          },
        },
        {
          label: '4. Perks & Upgrades',
          name: 'perks-upgrades',
          content: function () {
            return <PerksAndUpsells selectedUpsells={selected_upsells} upsellCategories={upsell_categories} section="SHIFT" />
          },
        },
      ],
      headerLabel,
      <div className="button bottom" style={{ padding: '6px', borderTop: '1px solid #CACACA', color: '#888' }}>
        <a
          style={{ maxHeight: '38px' }}
          data-test="sr-button-save"
          onClick={_.partial(submitShift, id, venue_name, shift, type, selectedDay, floorplans_data.seating_areas)}
        >
          <span>Review Changes</span>
        </a>
      </div>,
      id,
      media_url,
      _.partial(helpers.onMountCallBack, startDay),
      _.partial(helpers.onMountCallBack, startDay),
      onCloseClickHandler
    )
  }

  return {
    add: addSlide,
    edit: editSlide,
    view: viewSlide,
    weekDaysOptions: helpers.weekDaysOptions,
  }
})()
